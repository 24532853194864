import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEPOSIT_PAYMENT_METHOD_KEY, DEPOSIT_REDIRECT_URL } from 'lib/constants/accounts/deposit';
import { SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import { Beneficiary } from 'services/beneficiary/constants';
import { GetTagResponse } from 'types/send';

import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  accountsSliceReducerInitialState,
  BENEFICIARY_KEY,
  FINALIZE_SEND_PAGE,
  INACTIVE_MODAL_IS_OPEN,
  IS_ACTIVE,
  IS_BENEFICIARY,
  IS_MUTATION_PENDING,
  IS_TAGS_PENDING,
  NOVATAG_USERS,
  PAYMENT_METHOD_DURATION_KEY,
  SAVE_TO_BENEFICIARY,
  SECOND_SELECT_DATA,
  SELECTED_BANK,
  SEND_AMOUNT_VALUES,
  SEND_PAYMENT_INPUT_VALUES,
  SEND_PAYMENT_STEP_KEY,
  SendAmountValue,
  SendPaymentInputValues,
  VERIFIED_BANK_ACCOUNT,
} from './constants';

const accountsSlice = createSlice({
  name: ACCOUNTS_SLICE_REDUCER_PATH,
  initialState: accountsSliceReducerInitialState,
  reducers: {
    setUserIsActive: (state, { payload }: PayloadAction<boolean>) => {
      state[IS_ACTIVE] = payload;
    },
    setInactiveModalIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state[INACTIVE_MODAL_IS_OPEN] = payload;
    },
    setPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state[DEPOSIT_PAYMENT_METHOD_KEY] = payload;
    },
    setDepositRedirectUrl: (state, { payload }: PayloadAction<string>) => {
      state[DEPOSIT_REDIRECT_URL] = payload;
    },
    setSendPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state[SEND_PAYMENT_METHOD_KEY] = payload;
    },
    setDuration: (state, { payload }: PayloadAction<string>) => {
      state[PAYMENT_METHOD_DURATION_KEY] = payload;
    },
    setSendPaymentStep: (state, { payload }: PayloadAction<number>) => {
      state[SEND_PAYMENT_STEP_KEY] = payload;
    },
    setSendInputValues: (state, { payload }: PayloadAction<SendPaymentInputValues>) => {
      state[SEND_PAYMENT_INPUT_VALUES] = payload;
    },
    setVerifiedBankAccount: (state, { payload }: PayloadAction<string>) => {
      state[VERIFIED_BANK_ACCOUNT] = payload;
    },
    setSendAmountValues: (state, { payload }: PayloadAction<SendAmountValue>) => {
      state[SEND_AMOUNT_VALUES] = payload;
    },
    setSelectedBank: (state, { payload }: PayloadAction<string>) => {
      state[SELECTED_BANK] = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSecondSelectData: (state, { payload }: PayloadAction<any>) => {
      state[SECOND_SELECT_DATA] = payload;
    },
    setIsMutationPending: (state, { payload }: PayloadAction<boolean>) => {
      state[IS_MUTATION_PENDING] = payload;
    },
    setIsTagsPending: (state, { payload }: PayloadAction<boolean>) => {
      state[IS_TAGS_PENDING] = payload;
    },
    setTagResults: (state, { payload }: PayloadAction<GetTagResponse[]>) => {
      state[NOVATAG_USERS] = payload;
    },
    setFinalizeSendPage: (state, { payload }: PayloadAction<number>) => {
      state[FINALIZE_SEND_PAGE] = payload;
    },
    setSaveToBeneficiary: (state, { payload }: PayloadAction<boolean>) => {
      state[SAVE_TO_BENEFICIARY] = payload;
    },
    setIsBeneficiary: (state, { payload }: PayloadAction<boolean>) => {
      state[IS_BENEFICIARY] = payload;
    },
    setBeneficiary: (state, { payload }: PayloadAction<Beneficiary>) => {
      state[BENEFICIARY_KEY] = payload;
    },

    setDepositDetails: (
      state,
      { payload }: PayloadAction<{ amount: number; payment_method: string }>
    ) => {
      state.depositDetails = payload;
    },

    resetAccountSlice: () => {
      return accountsSliceReducerInitialState;
    },
  },
});

export const accountsReducer = accountsSlice.reducer;

export const {
  setPaymentMethod,
  setDepositRedirectUrl,
  setSendPaymentMethod,
  setDuration,
  setSendPaymentStep,
  setSendInputValues,
  setVerifiedBankAccount,
  setSendAmountValues,
  setSelectedBank,
  setSecondSelectData,
  setIsMutationPending,
  setIsTagsPending,
  setTagResults,
  setFinalizeSendPage,
  setSaveToBeneficiary,
  setIsBeneficiary,
  setUserIsActive,
  setInactiveModalIsOpen,
  setBeneficiary,
  setDepositDetails,
  resetAccountSlice,
} = accountsSlice.actions;
