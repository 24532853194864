import Modal, { ModalProps } from 'components/modal';
import { useGetCardBrands } from 'lib/utils';
import { X } from 'lucide-react';
import { useAppDispatch } from 'store';
import { setActiveCardBrand, setActiveVirtualCardBrand } from 'store/slices/cards/cardsSlice';
import { CardBrands } from 'types/configuration';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

interface CardTypeModalProps extends ModalProps {
  type: 'physical' | 'virtual';
}

const ChoseCardTypeModal = ({ ...props }: CardTypeModalProps) => {
  const cardType = useGetCardBrands();
  const physicalCards = cardType?.filter((x) => x.type === 'physical');
  const virtualCards = cardType?.filter((x) => x.type === 'virtual');

  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSelect = (type: CardBrands) => {
    dispatch(setActiveCardBrand(type));
    props.close();
  };

  const handleSelectVirtual = (type: CardBrands) => {
    dispatch(setActiveVirtualCardBrand(type));
    props.close();
  };

  return (
    <Modal {...props} className="py-7 px-5 w-full max-w-2xl scroll-component">
      <div className="flex justify-between">
        <p className="text-lg xl:text-2xl font-medium">Select card brand</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>

      <p className="mt-2 text-grey-text text-xs xl:text-base">
        Select one of our available card brands
      </p>

      <div className="mt-10 grid w-full gap-y-5">
        {props.type === 'physical' && (
          <div>
            {physicalCards?.map((type, idx) => (
              <button key={idx} onClick={() => handleSelect(type)}>
                <DashboardCardLayout className="flex space-x-8 text-start items-center">
                  <img
                    src={`https://res.cloudinary.com/platnova/assets/payment_methods/${type.id}.png`}
                    alt="gg"
                    className="w-6 xl:w-10 h-max"
                  />
                  <div>
                    <p className="text-base md:text-lg font-medium">{type.name}</p>
                    <p className="text-sm md:text-base">{type.description}</p>
                  </div>
                </DashboardCardLayout>
              </button>
            ))}
          </div>
        )}
        {props.type === 'virtual' && (
          <div className="space-y-3">
            {virtualCards?.map((type, idx) => (
              <button key={idx} onClick={() => handleSelectVirtual(type)}>
                <DashboardCardLayout className="flex space-x-8 text-start items-center">
                  <img
                    src={`https://res.cloudinary.com/platnova/assets/payment_methods/${type.id}.png`}
                    alt="gg"
                    className="w-6 xl:w-10 h-max"
                  />
                  <div>
                    <p className="text-base md:text-lg font-medium">{type.name}</p>
                    <p className="text-sm md:text-base">{type.description}</p>
                  </div>
                </DashboardCardLayout>
              </button>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChoseCardTypeModal;
