import Button from 'components/Buttons/Button';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Flag from 'components/flag';
import Input from 'components/Inputs/input';
import VerifyPinForm from 'components/verifyPinForm';
import { useFormik } from 'formik';
import useDisclosure from 'hooks/useDisclosure';
import { useGetExchangeValue } from 'lib/utils';
import { BadgeCheck, ChevronDown, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_PIN_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { useBuyBetting, useVerifyBetting } from 'services/refill';
import { useAuthorizePin } from 'services/settings';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { REFILL_SLICE_REDUCER_PATH } from 'store/slices/refill/constants';
import { resetRefillState } from 'store/slices/refill/refillSlice';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { useDebouncedCallback } from 'use-debounce';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import SelectDepositWalletModal from 'views/dashboard/accounts/components/selectDepositWalletModal';
import RecipientInput from 'views/dashboard/accounts/send/component/recipientInput';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import { number, object, string } from 'yup';

const BettingForm = ({ close }: { close: () => void }) => {
  const { activeRefill } = useAppSelector((state) => state[REFILL_SLICE_REDUCER_PATH]);
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();

  const [verifiedName, setVerifiedName] = useState('');

  const { data: account } = useGetAccount();
  const isPinValid = account?.data.additional_info.find((x) => x.id === 'is_pin_valid')?.value;

  const { mutate: verifyBetting, isPending: isLoadingVerifyBetting } = useVerifyBetting();
  const navigate = useNavigate();

  const {
    mutate: authorizePin,
    isPending: isAuthorizePinPending,
    error: authorizePinError,
    isError: isAuthorizePinError,
    isSuccess,
  } = useAuthorizePin();

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const { mutate: buyBetting, isPending: isLoadingBuyBetting } = useBuyBetting();

  const denominationKeys = Object.keys(activeRefill.vas_meta?.countries[0].denomination || {})
    .map(Number)
    .sort((a, b) => a - b);
  const minPrice = denominationKeys[0];
  const maxPrice = denominationKeys[1];

  const {
    isOpen: walletModalIsOpen,
    close: closeWalletModal,
    open: openWalletModal,
  } = useDisclosure();

  const formik = useFormik({
    initialValues: {
      currency: activeRefill.vas_meta?.countries[0].currency || '',
      id: activeRefill.id,
      account_number: '',
      amount: '',
      country_code: activeRefill.vas_meta?.countries[0].iso_code || '',
    },
    enableReinitialize: true,
    validationSchema: object().shape({
      currency: string().required(),
      account_number: string().required('Please enter id'),
      amount: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .required('Kindly choose a price')
        .min(minPrice, `Enter a value no less than ${minPrice}`)
        .max(maxPrice, `Enter a value no more than ${maxPrice}`),
      country_code: string().required(),
    }),
    onSubmit: () => {
      if (verifiedName.length > 0) {
        setPage(1);
      } else {
        formik.setErrors({ account_number: 'Invalid customer id' });
      }
    },
  });

  const customClose = () => {
    formik.resetForm();
    dispatch(resetRefillState());
    setPage(0);
    close();
  };

  function getCleanId(id: string) {
    const pattern = /[!@#$%^&*= ]/gi;

    return id.replaceAll(pattern, '');
  }

  const debouncedVerifyId = useDebouncedCallback((id) => {
    const cleanId = getCleanId(id);
    verifyBetting(
      {
        id: activeRefill.id,
        country_code: activeRefill.vas_meta?.countries[0].iso_code as string,
        number: cleanId,
      },
      {
        onSuccess: async (res) => {
          await formik.setFieldTouched('account_number', true);
          if (!res.data?.is_valid) {
            formik.setErrors({ account_number: 'Invalid customer id' });
            return;
          }
          return setVerifiedName(res.data.username as string);
        },
        onError: (err) => {
          handleErrors(err);
        },
      }
    );
  }, 1000);

  useEffect(() => {
    if (isSuccess) {
      const newValues = {
        ...formik.values,
        amount: Number(removeNonDigit(formik.values.amount)),
        base_amount: Number(removeNonDigit(formik.values.amount)),
      };
      buyBetting(newValues, {
        onSuccess: () => {
          notifySuccess(`Betting purchased successfully`);
          formik.resetForm();
          customClose();
        },
        onError: (err) => {
          handleErrors(err);
        },
      });
    } else {
      null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const rate = useGetExchangeValue(
    activeRefill.vas_meta?.countries[0].currency.toLowerCase() as string,
    activeWallet?.type as string
  );

  const paymentAmount = Number(formik.values.amount) * rate;

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
    };
  }

  // const handleOnChange = (
  //   value: string,
  // ) => {
  //   formik.setFieldValue('phone_number', `+${value}`);
  // };

  const denominations = Object.keys(activeRefill.vas_meta?.countries[0].denomination as object)
    .sort((a, b) => Number(a) - Number(b))
    .slice(0, 2)
    .map((value) => Number(value).toLocaleString());

  return (
    <div>
      {page === 0 && activeRefill.type === 'betting' && (
        <div className="space-y-6">
          <div className="flex justify-between pb-3 border-b">
            <p className="text-xl md:text-2xl font-medium capitalize">
              Top up {activeRefill.name} wallet
            </p>
            <X size={25} className="cursor-pointer" onClick={customClose} />
          </div>

          <img
            src={activeRefill.vas_meta?.countries[0].logo}
            alt={activeRefill.name}
            className="rounded-[20px] object-cover w-1/2 mx-auto aspect-video shrink-0"
          />

          <form className="space-y-6" onSubmit={formik.handleSubmit}>
            <div>
              <Input
                id="account_number"
                label="Customer ID"
                placeholder="Enter Customer ID"
                containerClassName="w-full"
                isLoading={isLoadingVerifyBetting}
                error={formik.errors.account_number}
                variant="flag"
                countryCode={activeRefill.vas_meta?.countries[0].iso_code}
                {...getInputProps('account_number')}
                onChange={(e) => {
                  const id = e.target.value;
                  setVerifiedName('');
                  const limit = activeRefill.vas_meta?.countries[0].limit;

                  getInputProps('account_number').onChange(e);

                  if (id && limit && id.length >= limit) {
                    debouncedVerifyId(id);
                  }
                }}
              />
              {verifiedName.length > 0 && (
                <div className="text-end -mt-5 text-green-500 font-bold flex items-center text-sm space-x-1 justify-end">
                  <p>{verifiedName}</p> <BadgeCheck size={14} />
                </div>
              )}
            </div>

            <div>
              <RecipientInput
                variant="fixed"
                fixedCurrency={activeRefill.vas_meta?.countries[0].currency}
                id="amount"
                label="Amount"
                {...getInputProps('amount')}
                value={formik.values['amount']}
              />
              <p className="text-grey-silent-text text-xs text-end">
                {`Enter a value between ${denominations.join(' and ')}`}
              </p>
            </div>

            <DashboardCardLayout className="space-y-2 w-full">
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Debit account</p>
                {wallets && (
                  <button
                    type="button"
                    onClick={openWalletModal}
                    className="flex items-center space-x-2 py-5"
                  >
                    <Flag
                      code={activeWallet ? activeWallet.country.code : wallets.data[0].country.code}
                      className="w-6 h-6"
                    />
                    <p className="text-sm md:text-base">
                      {activeWallet
                        ? activeWallet.type.toUpperCase()
                        : wallets?.data[0].type.toUpperCase()}
                    </p>
                    <ChevronDown className="w-6 h-6" />
                  </button>
                )}
              </div>
              <div className="space-y-7">
                <div className="flex justify-between items-center">
                  <p className="text-sm md:text-base text-black/70">You will pay</p>
                  <p className="text-sm md:text-base font-medium">
                    {activeWallet?.type.toUpperCase()} {formatAmount(paymentAmount.toString())}
                  </p>
                </div>
              </div>
            </DashboardCardLayout>
            <Button className="w-full mt-6" variant="purple" type="submit">
              Buy
            </Button>
          </form>
        </div>
      )}

      {page === 1 && (
        <div className="space-y-6">
          <div className="flex justify-end pb-3 border-b">
            <X size={25} className="cursor-pointer" onClick={customClose} />
          </div>

          {isPinValid ? (
            <VerifyPinForm
              close={customClose}
              error={authorizePinError}
              isError={isAuthorizePinError}
              isPending={isAuthorizePinPending || isLoadingBuyBetting}
              mutate={authorizePin}
            />
          ) : (
            <div className="py-10 md:py-20 px-5 md:px-24 w-full max-w-xl flex flex-col items- space-y-5 text-center">
              <p className="text-lg md:text-2xl font-semibold">Create PIN</p>
              <p className="text-sm md:text-base">
                For further security, you are required to create a transaction pin to proceed with
                this transaction.
              </p>
              <Button
                variant="purple"
                onClick={() => {
                  customClose();
                  navigate(SETTINGS_PIN_ROUTE);
                }}
              >
                Create Transaction Pin
              </Button>
            </div>
          )}
        </div>
      )}
      <SelectDepositWalletModal
        variant="debit"
        close={closeWalletModal}
        isOpen={walletModalIsOpen}
      />
    </div>
  );
};

export default BettingForm;
