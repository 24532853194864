/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button';
import MoneyInput from 'components/Inputs/moneyInput';
import {
  SEND_FROM_AMOUNT_KEY,
  SEND_FROM_CURRENCY_KEY,
  SEND_PAYMENT_METHOD_KEY,
  SEND_TO_AMOUNT_KEY,
  SEND_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/send';
import {
  useGetActiveWallet,
  useGetCurrencyCountry,
  useGetExchangeValue,
  useGetFormattedExchangeRate,
  useGetSendFees,
} from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { setSendPaymentStep } from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  BENEFICIARY_KEY,
  PAYMENT_METHOD_DURATION_KEY,
} from 'store/slices/account/constants';
import { RECIPIENT_CURRENCY_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import {
  setInsufficientFundModalIsOpen,
  setRecipientCountry,
} from 'store/slices/wallet/walletSlice';
import { formatAmount } from 'utils/numberFormat';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import RecipientInput from './component/recipientInput';
import useSendForm from './hooks/useSendForm';

const SendAmount = () => {
  const activeWallet = useGetActiveWallet();
  const {
    [PAYMENT_METHOD_DURATION_KEY]: duration,
    [BENEFICIARY_KEY]: beneficiary,
    isBeneficiary,
    [SEND_PAYMENT_METHOD_KEY]: paymentMethod,
  } = useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);

  const { getInputProps, values, setValue, onSubmit } = useSendForm();
  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();
  const { [RECIPIENT_CURRENCY_KEY]: recipientWalletCurrency } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const dispatch = useAppDispatch();
  const sendFees = useGetSendFees();
  const percentage = sendFees && sendFees.fee.percentage;
  const flat = sendFees && sendFees.fee.flat;

  const exchangeValue = useGetExchangeValue(
    activeWallet?.type ?? '',
    recipientWalletCurrency ?? ''
  );

  const country = useGetCurrencyCountry(beneficiary.currency);

  useEffect(() => {
    if (beneficiary.id) {
      dispatch(setRecipientCountry(country?.country.code as string));
    }
  }, [country]);

  useEffect(() => {
    setValue(SEND_TO_AMOUNT_KEY, Number(values[SEND_FROM_AMOUNT_KEY]) * exchangeValue);
    setValue(SEND_FROM_CURRENCY_KEY, activeWallet?.type);
    setValue(SEND_TO_CURRENCY_KEY, recipientWalletCurrency);
  }, [activeWallet?.type, exchangeValue, recipientWalletCurrency, setValue]);

  const exchange = useGetFormattedExchangeRate(
    activeWallet?.type as string,
    recipientWalletCurrency as string
  );

  const fee = (Number(String(values[SEND_TO_AMOUNT_KEY])) * Number(percentage)) / 100 + flat;

  const recipientValue =
    activeWallet?.type !== recipientWalletCurrency && fee
      ? formatAmount(String(Number(values[SEND_TO_AMOUNT_KEY]) - fee))
      : formatAmount(String(values[SEND_TO_AMOUNT_KEY]));

  const fromValue =
    activeWallet?.type !== recipientWalletCurrency
      ? formatAmount(String(values[SEND_FROM_AMOUNT_KEY]))
      : formatAmount(String(Number(values[SEND_FROM_AMOUNT_KEY]) + fee));

  const handleShowFee = (fee: string | number, currency?: string) => {
    if (fee === 0 || fee === '0' || fee === '0.00') {
      return 'Free';
    } else {
      return currency + ' ' + formatAmount(String(fee));
    }
  };

  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (activeWallet && activeWallet.amount) {
      if (Number(activeWallet.amount) < Number(values[SEND_FROM_AMOUNT_KEY]) + fee) {
        dispatch(setInsufficientFundModalIsOpen(true));
      } else {
        onSubmit();
      }
    }
  };
  return (
    <SubRouteLayout header="Send Payment">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() =>
              !isBeneficiary ? dispatch(setSendPaymentStep(2)) : dispatch(setSendPaymentStep(0))
            }
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <form className="space-y-8 mt-5 xl:mt-0 xl:space-y-10" onSubmit={handleSumbit}>
            <div className="flex justify-between items-center xl:space-y-0">
              <div>
                <p className="text-lg xl:text-2xl font-medium">Send payment</p>
              </div>
              <img
                className="w-6 h-6 xl:w-10 xl:h-10"
                src={`https://res.cloudinary.com/platnova/assets/payment_methods/${paymentMethod}`}
                alt={paymentMethod}
              />
            </div>

            <div className="flex flex-col items-center">
              <DashboardCardLayout className="xl:w-[37.5rem]">
                {!isLoadingWallets && wallets && wallets.data.length ? (
                  <MoneyInput
                    label="You send"
                    variant="send"
                    id={SEND_FROM_AMOUNT_KEY}
                    {...getInputProps(SEND_FROM_AMOUNT_KEY)}
                    value={values[SEND_FROM_AMOUNT_KEY]}
                  />
                ) : (
                  <Skeleton className="h-[8.2rem]" />
                )}
              </DashboardCardLayout>

              <DashboardCardLayout className="xl:w-[37.5rem] mt-8 xl:mt-10">
                {!isLoadingWallets && wallets ? (
                  <RecipientInput
                    label="Select recipient's country and currency"
                    id={SEND_TO_AMOUNT_KEY}
                    {...getInputProps(SEND_TO_AMOUNT_KEY)}
                    value={values[SEND_TO_AMOUNT_KEY]}
                    isFixed
                    touched
                  />
                ) : (
                  <Skeleton className="h-[8.2rem]" />
                )}
              </DashboardCardLayout>

              <div className="mt-8 xl:mt-10 space-y-8 xl:space-y-10 w-full">
                <DashboardCardLayout className="xl:w-[37.5rem] space-y-6">
                  <div className="flex justify-between items-center">
                    <p className="text-xs xl:text-base">Rate</p>
                    <p className="text-xs xl:text-base font-medium">{exchange}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs xl:text-base">Estimated arrival</p>
                    <p className="text-xs xl:text-base font-medium">{duration || 'Instant'}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs xl:text-base">
                      Fee ({percentage ? percentage : 0}%
                      {flat > 0 && ` + ${flat}${recipientWalletCurrency.toUpperCase()}`})
                    </p>
                    <p className="text-xs xl:text-base font-medium">
                      {isNaN(fee)
                        ? handleShowFee(0)
                        : handleShowFee(fee, recipientWalletCurrency.toUpperCase())}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs xl:text-base">You will send</p>
                    <p className="text-xs xl:text-base font-medium">
                      {activeWallet?.type.toUpperCase()} {fromValue || formatAmount('0')}
                    </p>
                  </div>

                  <div className="flex justify-between items-center">
                    <p className="text-xs xl:text-base">They will receive</p>
                    <p className="text-xs xl:text-base font-medium">
                      {recipientWalletCurrency.toUpperCase()} {recipientValue || formatAmount('0')}
                    </p>
                  </div>
                </DashboardCardLayout>

                <Button variant="purple" className="w-full" type="submit">
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default SendAmount;
