import { ChevronLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';

import SubRouteLayout from '../components/subRouteLayout';

const Swap = () => {
  return (
    <SubRouteLayout header="Swap" className="px-0">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="mt-10 xl:mt-0 md:w-[37rem]">
            <div>
              <iframe
                title="swap"
                src="https://swap.platnova.com"
                className="w-full min-h-screen"
              />
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Swap;
