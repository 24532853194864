import { useAppSelector } from 'store';
import { ACCOUNTS_SLICE_REDUCER_PATH, SEND_PAYMENT_STEP_KEY } from 'store/slices/account/constants';

import SendPayment from '.';
import ConfirmSend from './confirmSend';
import SendAmount from './sendAmount';
import SupportedCurrencies from './supportedCurrencies';
import UserDetails from './userDetails';

const SendLayout = () => {
  const { [SEND_PAYMENT_STEP_KEY]: sendStep } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  return (
    <div>
      {!sendStep && <SendPayment />}
      {sendStep === 1 && <SupportedCurrencies />}
      {sendStep === 2 && <UserDetails />}
      {sendStep === 3 && <SendAmount />}
      {sendStep === 4 && <ConfirmSend />}
    </div>
  );
};

export default SendLayout;
