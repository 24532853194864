import Modal from 'components/modal';
import { X } from 'lucide-react';
import { GiftcardCountryMeta } from 'services/refill/types';
import { useAppDispatch } from 'store';
import { setGiftcardCountry } from 'store/slices/refill/refillSlice';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

const PickGiftcardCountryModal = ({
  isOpen,
  close,
  countries,
}: {
  isOpen: boolean;
  close: () => void;
  countries: GiftcardCountryMeta[];
}) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      className="p-8 w-full max-w-xl md:max-w-xl md:w-full scroll-component pb-24 xl:pb-8"
    >
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium capitalize">Select Country</p>
        <X size={25} className="cursor-pointer" onClick={close} />
      </div>

      {countries &&
        countries.map((country) => (
          <button
            key={country.name}
            onClick={() => {
              dispatch(setGiftcardCountry(country));
              close();
            }}
          >
            <DashboardCardLayout className="mt-3 flex space-x-6 items-center md:max-w-lg">
              <img
                src={country.flag}
                alt={country.name}
                className="w-6 h-6 aspect-square object-contain"
              />
              <div className="text-start">
                <p className="text-lg font-medium">{country.name}</p>
                <p className="text-sm text-grey-text">{country.iso_code}</p>
              </div>
            </DashboardCardLayout>
          </button>
        ))}
    </Modal>
  );
};

export default PickGiftcardCountryModal;
