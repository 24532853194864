import Flag from 'components/flag';
import Input from 'components/Inputs/input';
import { SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import { allCountries } from 'lib/countries/countries';
import { cn, useGetSendPaymentMethodCurrency } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useGetConfigurations } from 'services/configurations';
import { useAppDispatch, useAppSelector } from 'store';
import { setSendPaymentStep } from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import { setRecipientCountry, setRecipientCurrency } from 'store/slices/wallet/walletSlice';
import { Configuration, RecipientCurrenciesValueType } from 'types/configuration';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

const SupportedCurrencies = () => {
  const { data: configurations } = useGetConfigurations();
  const dispatch = useAppDispatch();
  const { [SEND_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const sendPaymentCurrencies = configurations?.data.find(
    (x): x is Configuration<RecipientCurrenciesValueType> => x.name === 'recipient_currencies'
  )?.value;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const supportedCurrencies = useGetSendPaymentMethodCurrency(paymentMethod);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [searchInput, setSearchInput] = useState('');

  const displayedCurrencies = sendPaymentCurrencies?.filter((x) =>
    supportedCurrencies.includes(x.type.toLowerCase())
  );

  const country = (countryName: string) => {
    return allCountries.find((x) => x.cca2 === countryName)?.name.common;
  };

  return (
    <SubRouteLayout header="Recipient Currency">
      <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <button
            onClick={() => dispatch(setSendPaymentStep(0))}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="w-full mt-5 xl:mt-0 space-y-4 xl:space-y-10">
            <div className="flex xl:space-y-0 justify-between items-center w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">Recipient Currency</p>
                <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                  Choose the currency your recipient will receive
                </p>
              </div>
              <img
                className="w-6 h-6 xl:w-10 xl:h-10"
                src={`https://res.cloudinary.com/platnova/assets/payment_methods/${paymentMethod}`}
                alt={paymentMethod}
              />
            </div>
            <Input
              id="search"
              searchIcon
              variant="secondary"
              placeholder="Search..."
              onChange={(e) => setSearchInput(e.target.value)}
            />

            <DashboardCardLayout className="space-y-5 xl:w-full mt-3 flex flex-col">
              {displayedCurrencies?.map((currency) => (
                <button
                  onClick={() => {
                    dispatch(setRecipientCurrency(currency.type.toLowerCase()));
                    dispatch(setRecipientCountry(currency.country.code.toLowerCase()));
                    dispatch(setSendPaymentStep(2));
                  }}
                  key={currency.country.code}
                  className={cn(
                    'w-full flex items-center justify-between rounded-[10px] px-[5%] py-2.5 hover:bg-platnova-secondary'
                  )}
                >
                  <div className="flex items-center space-x-5 ">
                    <Flag code={currency.country.code} />
                    <div className="flex flex-col items-start">
                      <p className="text-xs xl:text-base font-medium">
                        {country(currency.country.code)}
                      </p>
                      <p className="text-[0.625rem] xl:text-xs text-grey-silent-text">
                        {currency.description}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </DashboardCardLayout>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default SupportedCurrencies;
