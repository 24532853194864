import IconButton from 'components/Buttons/iconButton';
import { cn } from 'lib/utils';
import { Search } from 'lucide-react';
import { useState } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import { useSearchParams } from 'react-router-dom';

export interface InputSearchProp extends React.InputHTMLAttributes<HTMLInputElement> {
  isLoading?: boolean;
  variant?: 'primary' | 'secondary';
  touched?: boolean;
  error?: string;
  inputClassName?: string;
  containerClassName?: string;
  updateQuery?: boolean;
  customSearch?: (search: string) => void;
  returnAfterCustomSearch?: boolean;
}

const InputSearch = ({
  isLoading,
  disabled,
  variant = 'primary',
  touched,
  error,
  className,
  inputClassName,
  containerClassName,
  ...rest
}: InputSearchProp) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const [value, setValue] = useState(searchValue || '');

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value.length > 0) {
      setSearchParams({ search: value });
    } else {
      setSearchParams();
    }
  };

  return (
    <form
      onSubmit={handleSearch}
      className={cn(
        'relative flex w-full flex-row items-center rounded-xl border text-xs transition-all duration-300 ease-linear md:text-sm',
        'focus-within:ring focus-within:ring-platnova-purple/50',
        variant === 'primary' ? 'bg-[#FCFCFC] border border-[#EEEEEE]' : 'bg-secondary-grey',
        touched && error ? 'bg-primary-red/10' : '',
        containerClassName
      )}
    >
      <input
        placeholder="Search"
        type="text"
        inputMode="search"
        id="search"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={cn(
          'bg-[#FCFCFC] text-dark w-full rounded-xl border-none placeholder:text-dark',
          'px-2 py-2.5 pl-10 text-base shadow-none outline-none ring-0',
          'placeholder:text-xs placeholder:text-dark focus:ring-0 sm:pl-[3.5rem] md:px-4 md:pl-12 md:text-sm md:placeholder:text-sm lg:text-base lg:placeholder:text-base',
          className,
          inputClassName
        )}
        disabled={disabled || isLoading}
        {...rest}
      />
      <div className="text-dark absolute left-2 text-lg">
        <IconButton variant="ghost" type="submit" icon={Search} />
      </div>
      {isLoading && (
        <div className="text-dark absolute right-6 top-1/2 -translate-y-1/2">
          <ImSpinner2 className="animate-spin" />
        </div>
      )}
    </form>
  );
};

export default InputSearch;
