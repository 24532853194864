import Input from 'components/Inputs/input';
import useDisclosure from 'hooks/useDisclosure';
import { useGetAllSendPaymentMethods, useGetCurrency } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccount } from 'services/account';
import { useGetBeneficiaries } from 'services/beneficiary';
import { Beneficiary as BeneficiaryType } from 'services/beneficiary/constants';
import { useAppDispatch } from 'store';
import {
  setBeneficiary,
  setIsBeneficiary,
  setSendInputValues,
  setSendPaymentMethod,
  setSendPaymentStep,
} from 'store/slices/account/accountsSlice';
import { setRecipientCountry, setRecipientCurrency } from 'store/slices/wallet/walletSlice';
import { searchBeneficiaries } from 'utils/searchBeneficiaries';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import BeneficiariesModal from './component/beneficiariesModal';

const SendPayment = () => {
  const navigate = useNavigate();
  const { data: beneficiaries } = useGetBeneficiaries();
  const beneficiaryList = beneficiaries?.data;
  const dispatch = useAppDispatch();

  const [displayedBeneficiaries, setDisplayedBeneficiaries] = useState(beneficiaryList);

  const [searchInput, setSearchInput] = useState('');

  const {
    isOpen: beneficiaryModalIsOpen,
    close: closeBeneficiaryModal,
    open: openBeneficiaryModal,
  } = useDisclosure();
  const handleClickUser = (user: BeneficiaryType) => {
    const metaObj: { [key: string]: string } = {};
    user.meta.forEach((item) => {
      metaObj[item.id] = item.value;
    });
    dispatch(setSendInputValues(metaObj));
    dispatch(setSendPaymentMethod(user.payment_method));
    dispatch(setRecipientCurrency(user.currency));
    dispatch(setBeneficiary(user));
    dispatch(setSendPaymentStep(3));
    dispatch(setRecipientCountry(''));
    // dispatch(setFinalizeSendPage(1));
    dispatch(setIsBeneficiary(true));
    // navigate(ACCOUNTS_FINALIZE_SEND_ROUTE(sendPaymentMethod));
  };

  const paymentMethods = useGetAllSendPaymentMethods();
  const account = useGetAccount();
  useEffect(() => {
    const newValues = searchBeneficiaries(beneficiaryList, searchInput);
    if (JSON.stringify(displayedBeneficiaries) !== JSON.stringify(newValues)) {
      setDisplayedBeneficiaries(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaryList, searchInput]);

  const currency = useGetCurrency(account.data?.data.country_code ?? 'US');

  return (
    <div>
      <SubRouteLayout header="Send Payment">
        <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
          <div className="xl:flex items-start xl:space-x-11 w-full">
            <button
              onClick={() => navigate(-1)}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="w-full mt-5 xl:mt-0 space-y-4 xl:space-y-10">
              <div className="xl:flex space-y-4 xl:space-y-0 justify-between w-full">
                <div>
                  <p className="text-xl xl:text-2xl font-medium">Send Payment</p>
                  <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                    Select an existing beneficiary or payment method for a new recipient
                  </p>
                </div>

                {/* <Button
                    onClick={() => {
                      navigate(ACCOUNTS_FINALIZE_SEND_ROUTE(sendPaymentMethod));
                      dispatch(setIsBeneficiary(false));
                      dispatch(setFinalizeSendPage(0));
                    }}
                    variant="secondary"
                    className="flex items-center space-x-2 text-xs xl:text-base font-bold"
                  >
                    <Plus />
                    <span>Add Recipient</span>
                  </Button> */}
              </div>

              <Input
                id="search"
                searchIcon
                variant="secondary"
                placeholder="Search..."
                onChange={(e) => setSearchInput(e.target.value)}
              />

              {displayedBeneficiaries && displayedBeneficiaries.length > 0 && (
                <div>
                  <div className="flex justify-between items-center font-medium">
                    <p className="text-base">Beneficiaries</p>
                    <button className="text-platnova-purple text-sm" onClick={openBeneficiaryModal}>
                      See all
                    </button>
                  </div>
                  <DashboardCardLayout className="space-y-5 xl:w-full mt-3">
                    {displayedBeneficiaries &&
                      displayedBeneficiaries.slice(0, 2).map((beneficiary) => (
                        <button
                          onClick={() => handleClickUser(beneficiary)}
                          key={beneficiary.id}
                          className="bg-white w-full py-3 rounded-[10px] flex space-x-5"
                        >
                          <div className="bg-[#F5B12C] w-10 h-10 rounded-full flex justify-center items-center">
                            <p className="text-base">
                              {
                                beneficiary.meta.find(
                                  (x) => x.id === 'account_name' || x.id === 'beneficiary_name'
                                )?.value[0]
                              }
                            </p>
                          </div>
                          <div>
                            <p className="text-xs xl:text-base text-start">
                              {
                                beneficiary.meta.find(
                                  (x) =>
                                    x.id === 'account_name' ||
                                    x.id === 'beneficiary_name' ||
                                    x.id === 'revtag'
                                )?.value
                              }
                            </p>
                            <p className="text-[0.625rem] xl:text-xs text-grey-text text-start mt-1">
                              {beneficiary.meta.find((x) => x.id === 'bank_name')?.value}{' '}
                              {
                                beneficiary.meta.find(
                                  (x) => x.id === 'account_number' || x.id === 'iban'
                                )?.value
                              }
                            </p>
                          </div>
                        </button>
                      ))}
                  </DashboardCardLayout>
                </div>
              )}

              <div>
                <p>New Recipient</p>
                <div className="flex flex-col space-y-10">
                  <DashboardCardLayout className="space-y-10 xl:w-full mt-3 flex flex-col">
                    <button
                      className=""
                      onClick={() => {
                        dispatch(setSendPaymentMethod('bank_transfer'));
                        dispatch(setRecipientCountry(account.data?.data.country_code ?? 'US'));
                        dispatch(setRecipientCurrency(currency?.type as string));
                        dispatch(setSendPaymentStep(2));
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="flex items-center space-x-3 xl:space-x-6">
                          <img
                            src="https://res.cloudinary.com/platnova/assets/payment_methods/bank_transfer"
                            alt="gg"
                            className="w-6 h-6 xl:w-10 xl:h-10"
                          />
                          <div className="flex flex-col items-start text-start">
                            <p className="text-sm xl:text-base">Local Bank Transfer</p>
                            <p className="text-[0.625rem] xl:text-xs text-grey-text">
                              Best way to transfer funds locally
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>

                    {paymentMethods?.slice(1, 3).map((method) => (
                      <button
                        key={method.id}
                        className=""
                        onClick={() => {
                          dispatch(setSendPaymentMethod(method.id));
                          dispatch(setSendPaymentStep(1));
                        }}
                      >
                        <div className="flex justify-between">
                          <div className="flex items-center space-x-3 xl:space-x-6">
                            <img
                              src={`https://res.cloudinary.com/platnova/assets/payment_methods/${method.id}`}
                              alt="gg"
                              className="w-6 h-6 xl:w-10 xl:h-10"
                            />
                            <div className="flex flex-col items-start text-start">
                              <p className="text-sm xl:text-base">{method.name}</p>
                              <p className="text-[0.625rem] xl:text-xs text-grey-text">
                                {method.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </DashboardCardLayout>

                  <DashboardCardLayout className="space-y-10 xl:w-full mt-3 flex flex-col">
                    <button
                      className=""
                      onClick={() => {
                        dispatch(setSendPaymentMethod('bank_transfer'));
                        dispatch(setSendPaymentStep(1));
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="flex items-center space-x-3 xl:space-x-6">
                          <img
                            src="https://res.cloudinary.com/platnova/assets/payment_methods/bank_transfer"
                            alt="gg"
                            className="w-6 h-6 xl:w-10 xl:h-10"
                          />
                          <div className="flex flex-col items-start text-start">
                            <p className="text-sm xl:text-base">International Bank Transfer</p>
                            <p className="text-[0.625rem] xl:text-xs text-grey-text">
                              Send money globally with the best rates
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>

                    {paymentMethods?.slice(3).map((method) => (
                      <button
                        key={method.id}
                        className=""
                        onClick={() => {
                          dispatch(setSendPaymentMethod(method.id));
                          dispatch(setSendPaymentStep(1));
                        }}
                      >
                        <div className="flex justify-between">
                          <div className="flex items-center space-x-3 xl:space-x-6">
                            <img
                              src={`https://res.cloudinary.com/platnova/assets/payment_methods/${method.id}`}
                              alt="gg"
                              className="w-6 h-6 xl:w-10 xl:h-10"
                            />
                            <div className="flex flex-col items-start text-start">
                              <p className="text-sm xl:text-base">{method.name}</p>
                              <p className="text-[0.625rem] xl:text-xs text-grey-text">
                                {method.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </DashboardCardLayout>
                </div>
              </div>

              {/* <div className="space-y-5">
                  
                </div> */}

              <div></div>
            </div>
          </div>
        </div>
      </SubRouteLayout>
      <BeneficiariesModal
        isOpen={beneficiaryModalIsOpen}
        close={closeBeneficiaryModal}
        beneficiaries={beneficiaryList}
      />
    </div>
  );
};

export default SendPayment;
