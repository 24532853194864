export const GET_BENEFICIARIES_QUERY_KEY = 'get_beneficiaries' as const;

export interface Beneficiary {
  id: string;
  payment_method: string;
  meta: BeneficiaryMeta[];
  status: string;
  currency: string;
}

export interface BeneficiaryMeta {
  id: string;
  label: string;
  value: string;
}
