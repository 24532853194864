import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import Loader from 'components/Loader';
import { CREATE_BANK_ACCOUNT_ID_NUMBER_KEY } from 'lib/constants/accounts/deposit';
import { useGetCurrency } from 'lib/utils';
import { ChevronLeft, Copy } from 'lucide-react';
import { useEffect } from 'react';
import { BsFlagFill } from 'react-icons/bs';
import { GiCheckedShield } from 'react-icons/gi';
import { MdLightbulb, MdTimelapse } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useGetAccount, useGetIssuedBankAccount } from 'services/account';
import { useAppDispatch } from 'store';
import { setPaymentMethod } from 'store/slices/account/accountsSlice';
import { copyToClipboard } from 'utils/clipboard';

import useCreateBankAccountForm from './deposit/hooks/useCreateBankAccountForm';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const AccountDetails = () => {
  const { type } = useParams();
  const navigate = useNavigate();

  const { data: account } = useGetAccount();

  const { data: bankAccounts, isPending: isLoadingBankAccount } = useGetIssuedBankAccount();

  const filteredAccount = bankAccounts?.data.filter((x) => x.meta.currency === type?.toUpperCase());

  const currency = useGetCurrency(account?.data?.country_code as string)?.type;

  const dispatch = useAppDispatch();
  const {
    onSubmit,
    getInputProps,
    isPending,
    isError: isCreateBankAccountError,
    createAccountError,
  } = useCreateBankAccountForm();

  useEffect(() => {
    dispatch(setPaymentMethod(''));
  }, [dispatch]);

  useErrorNotification(isCreateBankAccountError, createAccountError);

  return (
    <div>
      {filteredAccount && !isPending ? (
        <SubRouteLayout header="Account Details">
          <div className="pb-20 xl:-ml-[4.688rem] mx-[5%] xl:mx-0">
            <div className="xl:flex items-start xl:space-x-11 max-w-[43.75rem]">
              <button
                onClick={() => navigate(-1)}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="mt-5 xl:mt-0">
                <p className="text-lg xl:text-2xl font-medium">
                  {type?.toUpperCase()} Account details
                </p>
                <p className="mt-2 text-grey-text text-xs xl:text-base">
                  Receive money from other bank accounts using the details below
                </p>

                <DashboardCardLayout className="mt-7 xl:mt-14 flex justify-between">
                  <p>Nova tag</p>
                  <p>@{account?.data?.username}</p>
                </DashboardCardLayout>

                {filteredAccount.map((account, idx) => (
                  <Accordion
                    key={idx}
                    type="single"
                    collapsible
                    className="bg-white shadow-sm p-5 rounded-[10px] mt-5 xl:w-[37.5rem] space-y-8"
                  >
                    <AccordionItem value="item-1">
                      <AccordionTrigger>{account.bank_name}</AccordionTrigger>
                      <AccordionContent className="space-y-8">
                        <div className="flex justify-between">
                          <p className="text-xs xl:text-base text-grey-text">Account number</p>
                          <div className="flex items-center space-x-3">
                            <p className="text-xs xl:text-base font-medium">
                              {account.account_number}
                            </p>
                            <button
                              onClick={() =>
                                copyToClipboard('Account number', account.account_number)
                              }
                            >
                              <Copy size={15} className="text-platnova-purple" />
                            </button>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <p className="text-xs xl:text-base text-grey-text min-w-max">
                            Account name
                          </p>
                          <div className="flex items-center space-x-3 max-w-[65%] md:max-w-[70%]">
                            <p className="text-xs xl:text-base font-medium truncate">
                              {account.account_name}
                            </p>
                            <button
                              onClick={() =>
                                copyToClipboard('Account name', account.account_number)
                              }
                            >
                              <Copy size={15} className="text-platnova-purple" />
                            </button>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <p className="text-xs xl:text-base text-grey-text">Bank name</p>
                          <div className="flex items-center space-x-3 max-w-[65%] md:max-w-[70%]">
                            <p className="text-xs xl:text-base font-medium truncate">
                              {account.bank_name}
                            </p>
                            <button onClick={() => copyToClipboard('Bank name', account.bank_name)}>
                              <Copy size={15} className="text-platnova-purple" />
                            </button>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <p className="text-xs xl:text-base text-grey-text">Account type</p>
                          <div className="flex items-center space-x-3">
                            <p className="text-xs xl:text-base font-medium">
                              {account.meta.account_type}
                            </p>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                ))}
                {/* <DashboardCardLayout className="mt-7 xl:mt-14 xl:w-[37.5rem] space-y-8">

              </DashboardCardLayout> */}

                <DashboardCardLayout className="mt-5 xl:w-[37.5rem] space-y-8">
                  <div className="flex space-x-3 xl:space-x-11 items-center">
                    <GiCheckedShield className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                    <p className="text-grey-text text-xs xl:text-base">
                      Your money is held in licensed banks.
                    </p>
                  </div>

                  <div className="flex space-x-3 xl:space-x-11 items-center">
                    <MdLightbulb className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                    <p className="text-grey-text text-xs xl:text-base w-fit">
                      Use these details to receive transfers from only NGN bank accounts.
                    </p>
                  </div>

                  <div className="flex space-x-3 xl:space-x-11 items-center">
                    <MdTimelapse className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                    <p className="text-grey-text text-xs xl:text-base">
                      Transfers are usually instant.
                    </p>
                  </div>

                  <div className="flex space-x-3 xl:space-x-11 items-center">
                    <BsFlagFill className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                    <p className="text-grey-text text-xs xl:text-base w-fit">
                      Only local transfers are accepted. For international transfers, please contact
                      support
                    </p>
                  </div>
                </DashboardCardLayout>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      ) : bankAccounts &&
        filteredAccount &&
        filteredAccount.length < 1 &&
        type === currency &&
        !isLoadingBankAccount ? (
        <SubRouteLayout header="BVN Verification">
          <div className="pb-20 xl:-ml-[4.688rem] mx-[5%] xl:mx-0">
            <div className="xl:flex items-start xl:space-x-11 max-w-[43.75rem]">
              <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </Link>

              <div className="mt-5 xl:mt-0">
                <DashboardCardLayout>
                  <div className="space-y-3">
                    <p className="text-lg xl:text-2xl font-medium">
                      Request {type?.toUpperCase()} Account Details
                    </p>
                    {/* <p className="text-xs md:text-sm">
                    You can check your BVN from your 9mobile, Airtel or MTN line by dialing
                    *565*0# after 48 hours of enrolment.
                  </p> */}

                    <p className="text-xs md:text-sm">
                      Please note that your BVN doesn't give us access to any of your bank accounts.
                    </p>
                  </div>
                  <form className="mt-6 space-y-3 xl:space-y-6" onSubmit={onSubmit}>
                    <div>
                      <Input
                        label="BVN"
                        type="text"
                        placeholder="Enter your BVN"
                        id={CREATE_BANK_ACCOUNT_ID_NUMBER_KEY}
                        {...getInputProps(CREATE_BANK_ACCOUNT_ID_NUMBER_KEY)}
                      />
                      <span className="text-xs">
                        Please ensure that the name and date of birth you provided earlier match the
                        BVN details
                      </span>
                    </div>

                    <Input label="Bank Account Currency" value="NGN" id="currency" disabled />

                    <div className="space-y-6">
                      <p className="text-center">
                        No BVN?{' '}
                        <span className="text-platnova-purple font-medium">
                          Get virtual account
                        </span>
                      </p>

                      <Button
                        variant="purple"
                        isLoading={isPending}
                        type="submit"
                        className="w-full"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </DashboardCardLayout>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      ) : (
        <Loader className="mt-20" />
      )}
    </div>
  );
};

export default AccountDetails;
