import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import MoneyInput from 'components/Inputs/moneyInput';
import {
  DEPOSIT_AMOUNT_KEY,
  DEPOSIT_CARD_KEY,
  DEPOSIT_CURRENCY_KEY,
  DEPOSIT_PAYMENT_METHOD_KEY,
  DEPOSIT_PAYMENT_OPTION_KEY,
} from 'lib/constants/accounts/deposit';
import { useGetDepositFees, useGetPaymentMethods } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { AiFillInfoCircle } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import useDepositForm from '../components/deposit/useDepositForm';
import PaymentMethod from '../components/paymentMethod';

const Deposit = () => {
  const { [DEPOSIT_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const allMethods = useGetPaymentMethods();
  const displayedMethod = allMethods?.find((x) => x.id === paymentMethod);

  const { onSubmit, getInputProps, values, setValue, depositError, isError, isPending } =
    useDepositForm();
  useErrorNotification(isError, depositError);

  const navigate = useNavigate();
  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const status = searchParams.get('status');

    if (status) {
      const timeout = setTimeout(() => {
        if (status === 'cancelled') {
          toast.error('Transaction cancelled');
        } else {
          toast.error('Oops something went wrong');
        }
        const updatedParams = new URLSearchParams(searchParams);
        updatedParams.delete('status');
        navigate({ search: updatedParams.toString() }, { replace: true });
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [navigate, searchParams]);

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  useEffect(() => {
    setValue(DEPOSIT_PAYMENT_OPTION_KEY, displayedMethod?.id);
    setValue(DEPOSIT_CURRENCY_KEY, activeWallet?.type);
  }, [activeWallet, displayedMethod, setValue]);

  const depositFees = useGetDepositFees();
  const percentage = depositFees?.fee.percentage;
  const flat = depositFees && depositFees.fee.flat;

  const fee =
    (Number(removeNonDigit(String(values[DEPOSIT_AMOUNT_KEY]))) * Number(percentage)) / 100 +
    Number(flat);

  const handleShowFee = (fee: string | number, currency?: string) => {
    if (fee === 0 || fee === '0' || fee === '0.00') {
      return 'Free';
    } else {
      return currency + ' ' + formatAmount(String(fee));
    }
  };
  // const limitedFee =
  //   fee > Number(depositFees?.fee.max_amount) ? Number(depositFees?.fee.max_amount) : fee;

  // http://localhost:3000/payments/process/?status=successful&status=cancelled&tx_ref=TR_SbRvjEyXVrWsh6hNUsks7yhasY8UuK

  return (
    <SubRouteLayout header="Deposit">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <form className="space-y-8 mt-5 xl:mt-0 xl:space-y-10" onSubmit={onSubmit}>
            <div>
              <p className="text-lg xl:text-2xl font-medium">Deposit</p>
              <p className="mt-2 text-grey-text text-xs xl:text-base">
                Securely add money to your selected wallet
              </p>
            </div>

            <DashboardCardLayout className="xl:w-[37.5rem]">
              {!isLoadingWallets && wallets && wallets.data.length ? (
                <MoneyInput
                  label="Amount"
                  id={DEPOSIT_AMOUNT_KEY}
                  {...getInputProps(DEPOSIT_AMOUNT_KEY)}
                  value={values[DEPOSIT_AMOUNT_KEY]}
                />
              ) : (
                <Skeleton className="h-[8.2rem]" />
              )}
            </DashboardCardLayout>

            <DashboardCardLayout className="xl:w-[37.5rem]">
              <PaymentMethod type="deposit" />
              {/* <AnimatePresence>
                <div className="mt-2 w-full flex items-center justify-between">
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                    className="pl-1 text-[0.625rem] md:text-xs font-semibold text-error-primary"
                  >
                    {errors[DEPOSIT_PAYMENT_OPTION_KEY]}
                  </motion.div>
                </div>
              </AnimatePresence> */}
            </DashboardCardLayout>

            {paymentMethod === DEPOSIT_CARD_KEY && (
              <div className="bg-[#8BB2FF4D] rounded-[10px] py-4 xl:py-8 px-3 xl:px-6 flex space-x-5 items-center xl:w-[37.5rem]">
                <AiFillInfoCircle className="stroke-[#8BB2FF4D] fill-[#021E65] w-9 h-9" />
                <p className="font-light text-[#021E65] text-xs xl:text-base">
                  To avoid delays, please ensure that the cardholder name matches the name on your
                  account.
                </p>
              </div>
            )}

            <DashboardCardLayout className="xl:w-[37.5rem] space-y-3 xl:space-y-6">
              <div className="flex justify-between items-center">
                <p className="text-xs xl:text-base">
                  Fee ({percentage ? percentage : 0}%
                  {(flat as number) > 0 && ` + ${activeWallet?.type.toUpperCase()} ${flat}`})
                </p>
                <p className="text-xs xl:text-base font-medium">
                  {isNaN(fee)
                    ? handleShowFee(0)
                    : handleShowFee(fee, activeWallet?.type.toUpperCase())}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs xl:text-base">Payment method</p>
                <p className="text-xs xl:text-base font-medium">
                  {displayedMethod ? displayedMethod.name : '-'}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs xl:text-base">You will pay</p>
                <p className="text-xs xl:text-base font-medium">
                  {activeWallet?.symbol}{' '}
                  {isNaN(Number(removeNonDigit(String(values[DEPOSIT_AMOUNT_KEY]))) + fee)
                    ? 0
                    : formatAmount(
                        String(Number(removeNonDigit(String(values[DEPOSIT_AMOUNT_KEY]))) + fee)
                      )}
                </p>
              </div>
            </DashboardCardLayout>

            <Button variant="purple" className="w-full" type="submit" isLoading={isPending}>
              Pay now
            </Button>
          </form>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Deposit;
