/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import Avatar from 'components/avatar';
import useErrorNotification from 'components/error/useErrorNotification';
import Loader from 'components/Loader';
import { SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import { useEffect, useState } from 'react';
import { searchNovatag } from 'services/account/function';
import { accountKeys } from 'services/account/key';
import {
  GET_BANK_BRANCHES_QUERY_KEY,
  GET_BANKS_QUERY_KEY,
  GET_CRYPTO_NETWORKS_QUERY_KEY,
  GET_CRYPTOS_QUERY_KEY,
  VERIFY_BANK_ACCOUNT_MUTATION_KEY,
} from 'services/banks/constants';
import { HookType, useDynamicHook, UseDynamicHookParams } from 'services/constants';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setIsMutationPending,
  setIsTagsPending,
  setSecondSelectData,
  setSelectedBank,
  setSendInputValues,
  setSendPaymentStep,
  setTagResults,
  setVerifiedBankAccount,
} from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  IS_MUTATION_PENDING,
  IS_TAGS_PENDING,
  NOVATAG_USERS,
  SECOND_SELECT_DATA,
  SELECTED_BANK,
  SEND_PAYMENT_INPUT_VALUES,
  SendPaymentInputValues,
  VERIFIED_BANK_ACCOUNT,
} from 'store/slices/account/constants';
import { RECIPIENT_COUNTRY_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { Fields } from 'types/configuration';
import { GetTagResponse } from 'types/send';

import Input from './input';
import Select from './select';
import { InputProps } from './types';

interface DynamicInputProps extends InputProps {
  dynamicVariant: string;
  id: string;
  field: Fields;
}

const DynamicInput: React.FC<DynamicInputProps> = ({
  dynamicVariant = 'input',
  id,
  field,
  ...rest
}: DynamicInputProps) => {
  const { [RECIPIENT_COUNTRY_KEY]: recipientCountry } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const sendPaymentInputValues: SendPaymentInputValues = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH][SEND_PAYMENT_INPUT_VALUES]
  );

  const {
    [VERIFIED_BANK_ACCOUNT]: verifiedAccount,
    [SELECTED_BANK]: selectedBank,
    [SECOND_SELECT_DATA]: secondSelectData,
    [IS_MUTATION_PENDING]: isMutationPendingValue,
    [IS_TAGS_PENDING]: isTagsPending,
    [NOVATAG_USERS]: users,
    [SEND_PAYMENT_METHOD_KEY]: paymentMethod,
  } = useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);

  const dispatch = useAppDispatch();

  const [selectData, setSelectData] = useState<{ value: string; label: string; code: string }[]>();
  // console.log(selectData?.find((x) => x.value === selectedBank)?.code)
  const [inputValue, setInputValue] = useState('');

  const queries: UseDynamicHookParams[] = [
    {
      key: GET_BANKS_QUERY_KEY,
      type: 'queries',
      params: { countryCode: recipientCountry, bankCode: '' },
    },
    {
      key: GET_BANK_BRANCHES_QUERY_KEY,
      type: 'queries',
      params: { countryCode: recipientCountry, bankCode: '' },
    },
    {
      key: accountKeys.SEARCH_NOVATAG,
      type: 'queries',
      params: { tag: inputValue },
    },
    {
      key: GET_CRYPTOS_QUERY_KEY,
      type: 'queries',
      params: {},
    },
    {
      key: GET_CRYPTO_NETWORKS_QUERY_KEY,
      type: 'queries',
      params: { currency: '' },
    },
  ];

  const [query, setQuery] = useState<UseDynamicHookParams>(
    queries.find((x) => x.key === field.query.id) || queries[0]
  );

  const updateBankBranchesQuery = (bankCode: string) => {
    const updatedQuery = queries.find((q) => q.key === GET_BANK_BRANCHES_QUERY_KEY);
    if (updatedQuery) {
      setQuery({
        ...updatedQuery,
        params: { ...updatedQuery.params, bankCode },
      });
    }
  };

  const updateNetworksQuery = (currency: string) => {
    const updatedQuery = queries.find((q) => q.key === GET_CRYPTO_NETWORKS_QUERY_KEY);
    if (updatedQuery) {
      setQuery({
        ...updatedQuery,
        params: { ...updatedQuery.params, currency },
      });
    }
  };

  // const updateNovatagQuery = (tag: string) => {
  //   const updatedQuery = queries.find((q) => q.key === accountKeys.SEARCH_NOVATAG);
  //   if (updatedQuery) {
  //     setQuery({
  //       ...updatedQuery,
  //       params: { ...updatedQuery.params, tag },
  //     });
  //   }
  // };

  const mutation = { type: 'mutations' as HookType, key: VERIFY_BANK_ACCOUNT_MUTATION_KEY };
  const { mutate, isError, error, isPending: isMutationPending } = useDynamicHook(mutation);

  useErrorNotification(isError, error);

  // const queryHook = queryHooks[key]
  const { data, isPending, refetch } = useDynamicHook(query);

  const [selectedBranch, setSelectedBranch] = useState('');

  // if (dynamicVariant === 'select' && !field.query.id && selectedBank) {
  //   console.log(query)
  // }
  const handleSetValue = async (value: string | string[]) => {
    field.query.id
      ? dispatch(setSelectedBank(value as string))
      : setSelectedBranch(value as string);
    if (field.pair) {
      const code = field.query.id
        ? selectData?.find((x) => x.value === value)?.code
        : secondSelectData?.find((x) => x.value === value)?.code;
      if (paymentMethod !== 'crypto') {
        const updatedValues = {
          ...sendPaymentInputValues,
          [field.id]: value as string,
          [field.pair.external_key]: code as string,
        };
        dispatch(setSendInputValues(updatedValues));
        if (field.query.id) {
          if (field.on_select && !field.on_select.query?.required) {
            updateBankBranchesQuery(code || '');
          }
        } else {
          const updatedValues = {
            ...sendPaymentInputValues,
            [field.pair.external_key]: code as string,
            [field.pair.key]: value as string,
          };
          dispatch(setSendInputValues(updatedValues));
        }
      } else {
        const updatedValues = {
          ...sendPaymentInputValues,
          [field.pair.external_key]: value as string,
        };
        dispatch(setSendInputValues(updatedValues));
        if (field.query.id) {
          if (field.on_select) {
            updateNetworksQuery((value as string) || '');
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   if (dynamicVariant === 'search') {
  //     setQuery(queries[1])
  //     refetch()
  //     console.log(query, data)
  //   }
  // }, [inputValue])

  useEffect(() => {
    if (dynamicVariant === 'select' && field.query.id && data && !isPending) {
      if (data.data && query.key === GET_BANKS_QUERY_KEY) {
        const formattedData = data.data.map((x: any) => ({
          value: x.name,
          label: x.name,
          code: x.code,
        }));
        if (!selectData) {
          setSelectData(formattedData);
        } else if (formattedData !== selectData) {
          setSelectData(formattedData);
        }
        if (field.on_select && field.on_select.query?.required) {
          const foundkey = Object.keys(sendPaymentInputValues).find((key) =>
            field.on_select.query?.required.includes(key)
          );
          if (foundkey && field.pair) {
            const values = {
              bank_code: sendPaymentInputValues.bank_code,
              [foundkey]: sendPaymentInputValues[foundkey],
              country_code: recipientCountry.toUpperCase(),
            };
            if (values.bank_code && values.country_code && values[foundkey]) {
              mutate(values);
            }
          }
        }
      } else if (query.key === GET_BANK_BRANCHES_QUERY_KEY && data.data) {
        const formattedData = data.data.map((x: any) => ({
          value: x.branch_name,
          label: x.branch_name,
          code: x.branch_code,
        }));
        dispatch(setSecondSelectData(formattedData));
      } else if (query.key === GET_CRYPTOS_QUERY_KEY && data.data) {
        const formattedData = data.data.items.map((x: any) => ({
          value: x.symbol,
          label: x.name,
        }));
        setSelectData(formattedData);
        if (field.on_select && field.on_select.query?.id) {
          // const foundKey = Object.keys(sendP)
        }
      } else if (query.key === GET_CRYPTO_NETWORKS_QUERY_KEY && data.data) {
        const formattedData = data.data.map((x: any) => ({
          value: x.chain,
          label: x.name,
        }));
        dispatch(setSecondSelectData(formattedData));
      }
    }
  }, [data, isPending, selectedBank]);

  useEffect(() => {
    dispatch(setSecondSelectData([]));
    refetch();
  }, [selectedBank]);

  useEffect(() => {
    dispatch(setIsMutationPending(isMutationPending));
  }, [isMutationPending]);

  useEffect(() => {
    if (dynamicVariant === 'search') {
      dispatch(setIsTagsPending(isPending));
    }
  }, [isPending]);

  useEffect(() => {
    if (dynamicVariant === 'input' || dynamicVariant === 'phone') {
      const updatedValues = {
        ...sendPaymentInputValues,
        [field.id]: inputValue,
      };
      delete updatedValues.account_name;
      dispatch(setVerifiedBankAccount(''));
      dispatch(setSendInputValues(updatedValues));
      if (
        field.on_changed &&
        field.on_changed.limit &&
        field.on_changed.limit === inputValue.length
      ) {
        if (field.on_changed.query?.required) {
          const foundKey = Object.keys(sendPaymentInputValues).find((key) =>
            field.on_changed.query?.required.includes(key)
          );
          if (foundKey) {
            const values = {
              [field.id]: inputValue,
              [foundKey]: sendPaymentInputValues[foundKey],
              country_code: recipientCountry.toUpperCase(),
            };
            if (values[field.id] && values[foundKey] && values.country_code) {
              mutate(values);
            }
          }
        }
      }
    } else if (dynamicVariant === 'search') {
      if (
        field.on_changed &&
        field.on_changed.limit &&
        inputValue.length >= field.on_changed.limit
      ) {
        // updateNovatagQuery(inputValue)
        // if (!isPending && inputValue) {
        //   console.log(inputValue, query, data)
        // }
        searchNovatag(inputValue).then((res) => dispatch(setTagResults(res.data)));
      }
    }
  }, [inputValue]);

  useEffect(() => {
    // dispatch(setSendInputValues({}));
    dispatch(setVerifiedBankAccount(''));
    dispatch(setSelectedBank(''));
    setInputValue('');
  }, []);

  const handleClickUser = (user: GetTagResponse) => {
    dispatch(setSendInputValues({ tag_id: user.username }));
    dispatch(setSendPaymentStep(3));
  };

  return (
    <div className="w-full">
      {dynamicVariant === 'input' && (
        <div className="flex flex-col items-end">
          <Input
            id={id}
            {...rest}
            maxLength={rest.maxLength}
            placeholder={field.hint}
            value={
              field.id === 'account_name'
                ? verifiedAccount
                : sendPaymentInputValues[field.id]
                ? sendPaymentInputValues[field.id]
                : inputValue
            }
            onChange={(e) => setInputValue(e.target.value)}
            type={field.type === 'name' ? 'text' : field.type}
            isLoading={field.disabled && !field.hidden && isMutationPendingValue ? true : false}
          />
        </div>
      )}
      {dynamicVariant === 'phone' && (
        <div className="flex flex-col items-end">
          <Input
            id={id}
            {...rest}
            maxLength={rest.maxLength}
            placeholder={field.hint}
            value={inputValue}
            onChange={(e) => {
              let newValue = e.target.value;

              if (field.prefix.length) {
                if (!newValue.startsWith(field.prefix)) {
                  if (newValue.length < field.prefix.length) {
                    newValue = field.prefix;
                  } else {
                    newValue = field.prefix + ' ' + newValue.slice(field.prefix.length);
                  }
                }
              }

              setInputValue(newValue);
            }}
            type="text"
          />
          {field.disabled && !field.hidden && isMutationPendingValue && (
            <Loader className="w-max" title="Validating" />
          )}
        </div>
      )}
      {dynamicVariant === 'search' && (
        <div className="flex flex-col items-end">
          <Input
            id={id}
            {...rest}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      )}
      {dynamicVariant === 'select' && (
        <div>
          <Select
            id={id}
            label={rest.label}
            options={field.query.id ? selectData : secondSelectData}
            value={
              field.query.id && selectedBank
                ? selectedBank
                : sendPaymentInputValues[field.id]
                ? sendPaymentInputValues[field.id]
                : selectedBranch
            }
            setValue={handleSetValue}
            disabled={isPending}
            isLoading={isPending}
          />
        </div>
      )}

      {field.id === 'tag_ids' && (
        <div className="flex flex-col items-center space-y-3">
          {isTagsPending && query.key === accountKeys.SEARCH_NOVATAG ? (
            <Loader />
          ) : users.length && users[0].username.length ? (
            users.map((user) => (
              <button
                onClick={() => handleClickUser(user)}
                key={user.username}
                className="bg-white w-full p-3 rounded-[10px] flex space-x-5 hover:bg-platnova-secondary cursor-pointer"
              >
                {!user.avatar || !user.avatar.length ? (
                  <div className="bg-[#F5B12C] w-12 h-12 rounded-full flex justify-center items-center">
                    <p className="text-base">{user.first_name[0]}</p>
                  </div>
                ) : (
                  <Avatar avatar={user.avatar} className="w-12 h-12 my-auto" />
                )}
                <div>
                  <p className="text-base">
                    {user.first_name} {user.last_name}
                  </p>
                  <p className="text-xs text-grey-text text-start">@{user.username}</p>
                </div>
              </button>
            ))
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DynamicInput;
