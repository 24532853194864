import { useMutation, useQuery } from '@tanstack/react-query';

import { GET_VAULT_WALLETS_QUERY_KEY, GET_VAULTS_QUERY_KEY } from './constants';
import { getVaults, getVaultWallets, postCreateVault } from './function';

export const useGetVaults = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_VAULTS_QUERY_KEY],
    queryFn: () => getVaults(),
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetVaultWallets = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_VAULT_WALLETS_QUERY_KEY],
    queryFn: () => getVaultWallets(),
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useCreateVault = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postCreateVault,
  });

  return { mutate, isPending, isError, error };
};
