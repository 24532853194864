import { Airtime, DataCategory } from 'services/refill/constants';
import { GiftCard, GiftcardCountryMeta } from 'services/refill/types';

export const BILLS_COUNTRY_KEY = 'bills_country' as const;
export const REFILL_SLICE_REDUCER_PATH = 'refill_slice' as const;

export const refillSliceInitialState = {
  [BILLS_COUNTRY_KEY]: 'NG',
  activeRefill: {
    id: '',
    name: '',
    slug: '',
    type: '',
    giftcard_meta: undefined as GiftCard['giftcard_meta'] | undefined,
    vas_meta: undefined as Airtime['vas_meta'] | undefined,
  },
  giftcardCountry: undefined as GiftcardCountryMeta | undefined,
  plans: undefined as DataCategory | undefined,
};
