/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button';
import MoneyInput from 'components/Inputs/moneyInput';
import useDisclosure from 'hooks/useDisclosure';
import {
  CONVERT_FROM_AMOUNT_KEY,
  CONVERT_FROM_CURRENCY_KEY,
  CONVERT_TO_AMOUNT_KEY,
  CONVERT_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/convert';
import { useGetConvertFees, useGetExchangeValue, useGetFormattedExchangeRate } from 'lib/utils';
import { ArrowDownUp, ChevronLeft, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import {
  TO_ACCOUNT_WALLET_ID_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import {
  setInsufficientFundModalIsOpen,
  setToAccountWallet,
  setWallet,
} from 'store/slices/wallet/walletSlice';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import ConvertConfirmationModal from './components/convertConfirmationModal';
import ToWalletInput from './components/toWalletInput';
import useConvertForm from './hooks/useConvertForm';
import NewAccountModal from '../components/newAccountModal';

const Convert = () => {
  const [rotate, setRotate] = useState(false);

  const dispatch = useAppDispatch();

  const { onSubmit, getInputProps, isPending, setValue, values, openModal, setOpenModal } =
    useConvertForm();

  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId, [TO_ACCOUNT_WALLET_ID_KEY]: toAccountWalletId } =
    useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const activeToWallet =
    toAccountWalletId.length > 0
      ? wallets?.data.find((x) => x.id === toAccountWalletId)
      : wallets?.data[1];

  const convertFees = useGetConvertFees();
  const percentage = convertFees && convertFees[0]?.fee.percentage;

  const fee =
    (Number(removeNonDigit(String(values[CONVERT_FROM_AMOUNT_KEY]))) * Number(percentage)) / 100;

  const handleShowFee = (fee: string | number, currency?: string) => {
    if (fee === 0 || fee === '0' || fee === '0.00') {
      return 'Free';
    } else {
      return currency + ' ' + formatAmount(String(fee));
    }
  };

  const exchangeValue = useGetExchangeValue(activeWallet?.type ?? '', activeToWallet?.type ?? '');

  const handleSwitch = () => {
    const from = walletId;
    const to = toAccountWalletId;

    setValue(CONVERT_FROM_AMOUNT_KEY, 0);
    setValue(CONVERT_TO_AMOUNT_KEY, 0);

    dispatch(setWallet(to));
    dispatch(setToAccountWallet(from));
    setRotate(true);
  };

  const handleTransitionEnd = () => {
    setRotate(false);
  };

  useEffect(() => {
    if (!walletId && wallets?.data.length && wallets.data.length > 1) {
      dispatch(setWallet(wallets.data[0].id));
      dispatch(setToAccountWallet(wallets.data[1].id));
    }
  }, [dispatch, walletId, wallets]);

  useEffect(() => {
    setValue(CONVERT_TO_AMOUNT_KEY, Number(values[CONVERT_FROM_AMOUNT_KEY]) * exchangeValue);
    setValue(CONVERT_FROM_CURRENCY_KEY, activeWallet?.type);
    setValue(CONVERT_TO_CURRENCY_KEY, activeToWallet?.type);
  }, [activeToWallet?.type, activeWallet?.type, exchangeValue, setValue]);

  const exchange = useGetFormattedExchangeRate(
    activeWallet?.type as string,
    activeToWallet?.type as string
  );

  const { close: closeConfirmationModal } = useDisclosure();

  const handleCloseConfirmationModal = () => {
    closeConfirmationModal(), setOpenModal(false);
  };

  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (activeWallet && activeWallet.amount) {
      if (Number(activeWallet.amount) < Number(values[CONVERT_FROM_AMOUNT_KEY]) + fee) {
        dispatch(setInsufficientFundModalIsOpen(true));
      } else {
        onSubmit();
      }
    }
  };

  const {
    isOpen: isNewAccountModalOpen,
    open: openNewAccountModal,
    close: closeNewAccountModal,
  } = useDisclosure();

  return (
    <SubRouteLayout header="Convert">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <form className="space-y-8 mt-5 xl:mt-0 xl:space-y-10" onSubmit={handleSumbit}>
            <div className="xl:flex justify-between space-y-4 xl:space-y-0">
              <div>
                <p className="text-lg xl:text-2xl font-medium">Convert</p>
                <p className="mt-2 text-grey-text text-xs xl:text-base">
                  Securely move funds across wallets
                </p>
              </div>
              {wallets && wallets.data.length < 1 && (
                <Button
                  variant="purple"
                  onClick={openNewAccountModal}
                  className="flex items-center w-max space-x-1 xl:space-x-3 p-2 xl:p-3"
                >
                  <Plus className="w-3 h-3 xl:w-6 xl:h-6" />
                  <p className="text-[0.625rem] xl:text-base">New Account</p>
                </Button>
              )}
            </div>

            <div className="flex flex-col items-center">
              <DashboardCardLayout className="xl:w-[37.5rem]">
                {!isLoadingWallets && wallets && wallets.data.length ? (
                  <MoneyInput
                    label="From"
                    variant="convert"
                    id={CONVERT_FROM_AMOUNT_KEY}
                    {...getInputProps(CONVERT_FROM_AMOUNT_KEY)}
                    value={values[CONVERT_FROM_AMOUNT_KEY]}
                  />
                ) : (
                  <Skeleton className="h-[8.2rem]" />
                )}
              </DashboardCardLayout>

              {wallets && wallets.data.length > 1 && (
                <div className="flex flex-col items-center w-full">
                  <button
                    onTransitionEnd={handleTransitionEnd}
                    style={{
                      transition: 'transform 0.5s ease',
                      transform: rotate ? 'rotate(360deg)' : 'rotate(0deg)',
                    }}
                    type="button"
                    onClick={handleSwitch}
                    className="bg-platnova-purple p-3 rounded-full w-max"
                  >
                    <ArrowDownUp className="stroke-white" />
                  </button>
                  <DashboardCardLayout className="xl:w-[37.5rem]">
                    {!isLoadingWallets && wallets ? (
                      <ToWalletInput
                        label="To"
                        id={CONVERT_TO_AMOUNT_KEY}
                        {...getInputProps(CONVERT_TO_AMOUNT_KEY)}
                      />
                    ) : (
                      <Skeleton className="h-[8.2rem]" />
                    )}
                  </DashboardCardLayout>

                  <div className="mt-8 xl:mt-10 space-y-8 xl:space-y-10 w-full">
                    <DashboardCardLayout className="xl:w-[37.5rem] space-y-6">
                      <div className="flex justify-between items-center">
                        <p className="text-xs xl:text-base">Rate</p>
                        <p className="text-xs xl:text-base font-medium">{exchange}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs xl:text-base">Fee ({percentage ? percentage : 0}%)</p>
                        <p className="text-xs xl:text-base font-medium">
                          {isNaN(fee)
                            ? handleShowFee(0)
                            : handleShowFee(fee, activeToWallet?.type.toUpperCase())}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs xl:text-base">From Amount</p>
                        <p className="text-xs xl:text-base font-medium">
                          {activeWallet?.type.toUpperCase()}{' '}
                          {formatAmount(String(values[CONVERT_FROM_AMOUNT_KEY]))}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs xl:text-base">To Amount</p>
                        <p className="text-xs xl:text-base font-medium">
                          {activeToWallet?.type.toUpperCase()}{' '}
                          {formatAmount(String(Number(values[CONVERT_TO_AMOUNT_KEY]) - fee))}
                        </p>
                      </div>
                    </DashboardCardLayout>

                    <Button variant="purple" className="w-full" type="submit" isLoading={isPending}>
                      Continue
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <ConvertConfirmationModal
        type="convert"
        close={handleCloseConfirmationModal}
        isOpen={openModal}
        fromAmount={values[CONVERT_FROM_AMOUNT_KEY]}
        toAmount={values[CONVERT_TO_AMOUNT_KEY]}
        fromCurrency={
          values[CONVERT_FROM_CURRENCY_KEY] && values[CONVERT_FROM_CURRENCY_KEY].length
            ? values[CONVERT_FROM_CURRENCY_KEY].toUpperCase()
            : ''
        }
        toCurrency={
          values[CONVERT_TO_CURRENCY_KEY] && values[CONVERT_TO_CURRENCY_KEY].length
            ? values[CONVERT_TO_CURRENCY_KEY].toUpperCase()
            : ''
        }
      />
      <NewAccountModal close={closeNewAccountModal} isOpen={isNewAccountModalOpen} />
    </SubRouteLayout>
  );
};

export default Convert;
