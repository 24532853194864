/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_DEPOSIT_ROUTE } from 'routes/path';

const PaymentIframe = ({ url, script }: { url: string; script?: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  useEffect(() => {
    const iframe = iframeRef.current;

    if (!iframe) return;

    const checkUrlChange = () => {
      const newUrl = iframe.contentWindow?.location.href;
      if (newUrl && newUrl !== url) {
        const urlSearchParams = new URL(newUrl).searchParams;
        const status = urlSearchParams.get('status');
        navigate(`${ACCOUNTS_DEPOSIT_ROUTE}?status=${status}`);
      }
    };

    const interval = setInterval(checkUrlChange, 1000); // Poll every second

    return () => clearInterval(interval); // Clean up on component unmount
  }, [navigate, url]);

  return (
    <div ref={divRef}>
      <iframe
        ref={iframeRef}
        src={url}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Deposit"
      />
    </div>
  );
};

export default PaymentIframe;
