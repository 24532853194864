import Modal, { ModalProps } from 'components/modal';
import { useAppDispatch, useAppSelector } from 'store';
import { REFILL_SLICE_REDUCER_PATH } from 'store/slices/refill/constants';
import { resetRefillState } from 'store/slices/refill/refillSlice';

import AirtimeForm from './airtimeForm';
import BettingForm from './bettingForm';
import CableForm from './cableForm';
import DataForm from './dataForm';
import ElectricityForm from './electricityForm';

const BillsPaymentModal = ({ isOpen, close }: ModalProps) => {
  const { activeRefill } = useAppSelector((state) => state[REFILL_SLICE_REDUCER_PATH]);
  const dispatch = useAppDispatch();

  const customClose = () => {
    // formik.resetForm()
    dispatch(resetRefillState());
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={customClose}
      className="p-5 md:p-8 w-full max-w-xl md:max-w-2xl md:w-full scroll-component pb-24 xl:pb-8"
    >
      {activeRefill.type === 'airtime' && <AirtimeForm close={customClose} />}
      {activeRefill.type === 'data' && <DataForm close={customClose} />}
      {activeRefill.type === 'betting' && <BettingForm close={customClose} />}
      {activeRefill.type === 'electricity' && <ElectricityForm close={customClose} />}
      {activeRefill.type === 'cabletv' && <CableForm close={customClose} />}
    </Modal>
  );
};

export default BillsPaymentModal;
