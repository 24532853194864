export function removeNonDigit(amount: string) {
  // Remove any non-digit characters
  if (!amount) return '';

  const cleanAmount = amount.replace(/[^0-9.]/g, '');
  const parts = cleanAmount.split('.');
  if (parts.length > 2) {
    const integerPart = parts.shift(); // Get integer part
    const decimalPart = parts.join(''); // Join remaining parts
    return `${integerPart}.${decimalPart}`;
  }

  return cleanAmount.length ? Number(cleanAmount).toString() : '';
}

export function removeNonDigitInput(amount: string): string {
  // Return early if the input is invalid
  if (!amount) return '';

  // Remove whitespace and commas
  const cleanedAmount = amount.replace(/[\s,]/g, '');

  // Handle multiple decimals by keeping only the first one
  const [integerPart, ...decimalParts] = cleanedAmount.split('.');
  const decimalPart = decimalParts.length > 0 ? decimalParts.join('') : '';

  // Combine the integer and decimal parts, allowing only one decimal
  return decimalParts.length > 0 ? `${integerPart}.${decimalPart}` : integerPart;
}

export function formatAmount(amount: string) {
  if (!amount.length) return '';
  // Remove any non-digit characters
  const cleanAmount = removeNonDigit(amount);

  // Remove any non-digit characters
  return `${
    cleanAmount
      ? parseFloat(cleanAmount).toLocaleString('en-us', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : ''
  }`;
}

export function formatInputAmount(amount: string, currency?: '₦' | '$'): string {
  // Remove any non-digit characters except the decimal point
  const cleanAmount = amount.replace(/[^0-9.]/g, '');

  // Check if the first character is a negative sign
  const firstCharacter = amount[0] === '-' ? '-' : '';

  // Split the number into the integer and fractional parts
  const [integerPart, fractionalPart] = cleanAmount.split('.');

  // Format the integer part to locale string
  const formattedInteger = integerPart ? parseInt(integerPart, 10).toLocaleString('en-us') : '';

  // Limit the fractional part to a maximum of 2 digits
  const truncatedFractional = fractionalPart?.slice(0, 2);

  // Combine the formatted integer with the truncated fractional part
  const formattedAmount =
    truncatedFractional !== undefined
      ? `${formattedInteger}.${truncatedFractional}`
      : formattedInteger;

  return `${firstCharacter}${currency ? `${currency} ` : ''}${formattedAmount}`;
}

export const truncateText = (text: string, length: number) => {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};
