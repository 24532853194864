import Loader from 'components/Loader';
import { useGetVaultWallets } from 'services/vault';

import MyVaults from './components/my-vaults';
import SelectVault from './selectVault';
import PaddedContainer from '../components/paddedContainer';

const Vault = () => {
  const { data: vaultWalletsResponse, isPending: isLoadingVaultWalletsResponse } =
    useGetVaultWallets();

  return (
    <PaddedContainer>
      {vaultWalletsResponse &&
        vaultWalletsResponse.data.length < 1 &&
        !isLoadingVaultWalletsResponse && <SelectVault />}
      {isLoadingVaultWalletsResponse && <Loader />}
      {vaultWalletsResponse &&
        vaultWalletsResponse.data.length >= 1 &&
        !isLoadingVaultWalletsResponse && <MyVaults />}
    </PaddedContainer>
  );
};

export default Vault;
