import Card from 'assets/quick-actions/card.png';
import Deposit from 'assets/quick-actions/deposit.png';
import Giftcard from 'assets/quick-actions/giftcard.png';
import Payout from 'assets/quick-actions/payout.png';
import Rates from 'assets/quick-actions/rates.png';
import Tuition from 'assets/quick-actions/tuition.jpg';
import ComingSoonModal from 'components/comingSoonModal';
import Flag from 'components/flag';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import useDisclosure from 'hooks/useDisclosure';
import { DEPOSIT_OTC_KEY } from 'lib/constants/accounts/deposit';
import { Activity, Download, EllipsisVertical, Send } from 'lucide-react';
import { useEffect } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import {
  ACCOUNTS_CONVERT_ROUTE,
  ACCOUNTS_DEPOSIT_FINALIZE_ROUTE,
  ACCOUNTS_DEPOSIT_ROUTE,
  ACCOUNTS_SEND_ROUTE,
  ACCOUNTS_SETTINGS_ROUTE,
  GIFTCARDS_ROUTE,
  ISSUED_ACCOUNTS_ROUTE,
  LINK_PHYSICAL_CARD_ROUTE,
  MY_ACCOUNTS_ROUTE,
  RATES_ROUTE,
  SWAP_ROUTE,
  TRANSACTIONS_ROUTE,
} from 'routes/path';
import { useGetAccount } from 'services/account';
import { useGetTransctions } from 'services/transactions';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setInactiveModalIsOpen,
  setPaymentMethod,
  setSendPaymentMethod,
  setSendPaymentStep,
  setUserIsActive,
} from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH, IS_ACTIVE } from 'store/slices/account/constants';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount } from 'utils/numberFormat';

import ActionButton from './components/actionButton';
import ConvertIcon from './components/convertIcon';
import RecentActivityCard from './components/recentActivityCard';
import DashboardCardLayout from '../components/dashboardCardLayout';
import PaddedContainer from '../components/paddedContainer';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();
  const { data: account } = useGetAccount();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const { [IS_ACTIVE]: userIsActive } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );
  const { data: transactions, isPending: isLoadingTransactions } = useGetTransctions();
  const handleRedirect = (url: string) => {
    userIsActive ? navigate(url) : dispatch(setInactiveModalIsOpen(true));
  };

  const {
    open: openComingSoonModal,
    close: closeComingSoonModal,
    isOpen: comingSoonModalIsOpen,
  } = useDisclosure();

  useEffect(() => {
    if (!userIsActive && account && account?.data.status === 'ACTIVE') {
      dispatch(setUserIsActive(true));
    }
  });
  const activeWallet = wallets?.data.find((x) => x.id === walletId);

  return (
    <PaddedContainer>
      <DashboardCardLayout>
        {!isLoadingWallets && wallets && wallets.data.length > 0 ? (
          <div className="w-full flex justify-between">
            <button
              onClick={() => {
                handleRedirect(MY_ACCOUNTS_ROUTE);
              }}
            >
              <div className="flex space-x-2 items-center">
                <p className="text-xl xl:text-[1.75rem] font-medium">
                  {activeWallet
                    ? activeWallet.type.toUpperCase() + ' ' + formatAmount(activeWallet.amount)
                    : wallets.data[0].type.toUpperCase() +
                      ' ' +
                      formatAmount(wallets.data[0].amount)}
                </p>
                <div className="p-1 rounded-full bg-platnova-secondary">
                  <FaCaretDown className="xl:w-4 xl:h-4 text-platnova-purple" />
                </div>
              </div>
              <p className="mt-2 xl:mt-4 text-grey-silent-text text-xs xl:text-sm text-start">
                {activeWallet ? activeWallet.description : wallets.data[0].description}
              </p>
            </button>
            <button className="h-max" onClick={() => handleRedirect(ISSUED_ACCOUNTS_ROUTE)}>
              <Flag
                code={activeWallet ? activeWallet.country.code : wallets?.data[0].country.code}
              />
            </button>
          </div>
        ) : (
          <Skeleton className="h-12 xl:h-[3.75rem]" />
        )}

        <div className="grid grid-cols-4 xl:grid-cols-5 gap-5 xl:gap-14 mt-7">
          <ActionButton
            href={ACCOUNTS_SEND_ROUTE}
            icon={<Send className="stroke-platnova-purple mx-auto w-4 h-4 xl:w-6 xl:h-6" />}
            text="Send"
          />
          <ActionButton
            href={ACCOUNTS_DEPOSIT_ROUTE}
            icon={<Download className="stroke-platnova-purple mx-auto w-4 h-4 xl:w-6 xl:h-6" />}
            text="Deposit"
          />
          <ActionButton href={ACCOUNTS_CONVERT_ROUTE} icon={<ConvertIcon />} text="Convert" />
          <ActionButton
            href={ACCOUNTS_SETTINGS_ROUTE}
            icon={
              <EllipsisVertical className="stroke-platnova-purple mx-auto w-4 h-4 xl:w-6 xl:h-6" />
            }
            text="Details"
          />
        </div>
      </DashboardCardLayout>

      <DashboardCardLayout>
        <div className="w-full flex justify-between items-center">
          <p className="text-grey-silent-text text-sm xl:text-base">Recent activity</p>
          <button
            onClick={() => navigate(TRANSACTIONS_ROUTE)}
            className="text-platnova-purple text-xs xl:text-sm"
          >
            See all
          </button>
        </div>

        <div className="mt-4 xl:mt-8 grid">
          <div className="space-y-5">
            {!isLoadingTransactions && transactions && transactions.data.items.length ? (
              transactions.data.items
                .slice(0, 3)
                .map((transaction) => (
                  <RecentActivityCard key={transaction.id} transaction={transaction} />
                ))
            ) : isLoadingTransactions ? (
              <Skeleton count={3} containerClassName="w-full" className="h-12 xl:h-16" />
            ) : (
              <div className="flex flex-col items-center py-6 space-y-3">
                <Activity stroke="#7049F7" size={24} />
                <p className="text-xs md:text-sm font-medium">
                  Your recent activities will appear here
                </p>
              </div>
            )}
          </div>
        </div>
      </DashboardCardLayout>

      <DashboardCardLayout className="">
        <div className="w-full flex justify-between items-center">
          <p className="text-grey-silent-text">Quick actions</p>
        </div>

        <Carousel className="md:w-full max-w-[80%] mx-auto xl:max-w-[37.5rem] mt-8">
          <CarouselContent>
            <CarouselItem
              // onClick={() => customOpen(item)}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              <Link to={LINK_PHYSICAL_CARD_ROUTE}>
                <img
                  src={Card}
                  alt="link card"
                  className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
                />
                <p className="mt-2 text-sm md:text-base text-center">Link Card</p>
              </Link>
            </CarouselItem>

            <CarouselItem
              onClick={openComingSoonModal}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              {/* <Link to={LINK_PHYSICAL_CARD_ROUTE}> */}
              <img
                src={Tuition}
                alt="tuition"
                className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
              />
              <p className="mt-2 text-sm md:text-base text-center">Tuition</p>
              {/* </Link> */}
            </CarouselItem>

            <CarouselItem
              onClick={() => navigate(RATES_ROUTE)}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              <img
                src={Rates}
                alt="rates"
                className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
              />
              <p className="mt-2 text-sm md:text-base text-center">Rates</p>
            </CarouselItem>

            <CarouselItem
              onClick={() => navigate(SWAP_ROUTE)}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              {/* <Link to={LINK_PHYSICAL_CARD_ROUTE}> */}
              <img
                src={Rates}
                alt="rates"
                className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
              />
              <p className="mt-2 text-sm md:text-base text-center">Swap</p>
              {/* </Link> */}
            </CarouselItem>

            <CarouselItem
              onClick={() => navigate(GIFTCARDS_ROUTE)}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              {/* <Link to={LINK_PHYSICAL_CARD_ROUTE}> */}
              <img
                src={Giftcard}
                alt="giftcard"
                className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
              />
              <p className="mt-2 text-sm md:text-base text-center">Giftcards</p>
              {/* </Link> */}
            </CarouselItem>

            <CarouselItem
              onClick={() => {
                dispatch(setPaymentMethod(DEPOSIT_OTC_KEY));
                navigate(ACCOUNTS_DEPOSIT_FINALIZE_ROUTE);
              }}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              {/* <Link to={LINK_PHYSICAL_CARD_ROUTE}> */}
              <img
                src={Deposit}
                alt="deposit"
                className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
              />
              <p className="mt-2 text-sm md:text-base text-center">OTC Deposit</p>
              {/* </Link> */}
            </CarouselItem>

            <CarouselItem
              onClick={() => {
                dispatch(setSendPaymentMethod('otc_payout'));
                dispatch(setSendPaymentStep(1));
                navigate(ACCOUNTS_SEND_ROUTE);
              }}
              className="cursor-pointer basis-auto w-32 md:w-40"
            >
              {/* <Link to={LINK_PHYSICAL_CARD_ROUTE}> */}
              <img
                src={Payout}
                alt="payout"
                className="rounded-[20px] object-cover h-20 md:h-28 w-full shrink-0"
              />
              <p className="mt-2 text-sm md:text-base text-center">OTC Payout</p>
              {/* </Link> */}
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </DashboardCardLayout>
      <ComingSoonModal isOpen={comingSoonModalIsOpen} close={closeComingSoonModal} />
    </PaddedContainer>
  );
};

export default Dashboard;
