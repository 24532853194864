import { AnimatePresence, motion } from 'framer-motion';
import { cn } from 'lib/utils';
import { X } from 'lucide-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';

type Props = {
  header: string;
  children: ReactNode;
  closeRoute?: string;
  className?: string;
};
const SubRouteLayout = ({ header, children, closeRoute = ACCOUNTS_ROUTE, className }: Props) => {
  const navigate = useNavigate();

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{
          opacity: 0,
          scale: 0.5,
          transition: {
            duration: 2,
            ease: 'linear',
          },
        }}
        transition={{
          duration: 0.3,
          delay: 0.1,
          ease: 'linear',
        }}
        className="w-full min-h-screen bg-grey-background flex flex-col items-center"
      >
        <div
          className={cn(
            'py-5 px-[5%] xl:px-20 fixed hidden xl:block top-0 z-20 bg-white w-full ',
            className
          )}
        >
          <div className="max-w-[1540px] mx-auto w-full flex justify-between">
            <p className="font-medium text-lg xl:text-2xl">{header}</p>

            <button onClick={() => navigate(closeRoute)}>
              <X size={25} />
            </button>
          </div>
        </div>

        <div className="w-full h-full xl:flex xl:justify-center pt-8 xl:pt-40 px-4 xl:px-0">
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SubRouteLayout;
