import Button from 'components/Buttons/Button';
import Flag from 'components/flag';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import useDisclosure from 'hooks/useDisclosure';
import { ChevronDown, Eye, EyeOff, Plus } from 'lucide-react';
import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TRANSACTIONS_ROUTE, VAULT_DETAILS_ROUTE, VAULT_PORTFOLIO_ROUTE } from 'routes/path';
import { useGetConfigurations } from 'services/configurations';
import { useGetVaults, useGetVaultWallets } from 'services/vault';
import { VaultResponse } from 'services/vault/types';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { Configuration, ExchangeRate } from 'types/configuration';
import { formatAmount } from 'utils/numberFormat';
import RecentActivityCard from 'views/dashboard/accounts/components/recentActivityCard';
import SelectDepositWalletModal from 'views/dashboard/accounts/components/selectDepositWalletModal';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

import CreateVaultModal from './CreateVaultModal';

type ProgressRingProps = {
  radius: number;
  stroke: number;
  progress: number; // value between 0 and 100
};

const ProgressRing = ({ radius, stroke, progress }: ProgressRingProps) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      {/* Background Circle */}
      <circle
        stroke="#EDEDFB" // light background color
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Progress Circle */}
      <circle
        stroke="url(#gradient)" // Gradient applied
        fill="transparent"
        strokeWidth={stroke}
        strokeLinecap="round" // Rounded end for the stroke
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={strokeDashoffset}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{
          transition: 'stroke-dashoffset 0.35s',
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
      {/* Gradient Definition */}
      <defs>
        <linearGradient id="gradient" x1="1" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#8B5CF6" /> {/* Purple */}
          <stop offset="100%" stopColor="#6366F1" /> {/* Blue */}
        </linearGradient>
      </defs>
    </svg>
  );
};

const MyVaults = () => {
  const { data: vaultWalletsResponse } = useGetVaultWallets();
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];
  const { data: vaultResponse } = useGetVaults();

  const [visible, setVisible] = useState(false);

  const { data: configurations } = useGetConfigurations();

  const exchangeRates = configurations?.data.find(
    (x): x is Configuration<ExchangeRate> => x.name === 'exchange_rates'
  );

  const totalVault = vaultWalletsResponse?.data.reduce((sum, item) => {
    const fromRate = exchangeRates?.value.find((x) => x.currency === item.currency)?.rates;
    const toRate = exchangeRates?.value.find((x) => x.currency === activeWallet?.type)?.rates;

    const rate = fromRate?.buy === toRate?.buy ? 1 : Number(toRate?.buy) / Number(fromRate?.sell);

    const convertedAmount = item.amount * rate;

    return sum + convertedAmount;
  }, 0);

  const { isOpen, open, close } = useDisclosure();
  const {
    open: openCreateVaultModal,
    close: closeCreateVaultModal,
    isOpen: createVaultModalIsOpen,
  } = useDisclosure();

  const navigate = useNavigate();

  const progress = (item: VaultResponse) => {
    const createdAt = moment(item.created_at);
    const targetDate = createdAt.clone().add(item.duration, 'days');
    const now = moment();

    const elapsedTime = now.diff(createdAt);
    const totalDuration = targetDate.diff(createdAt);

    const progress = (elapsedTime / totalDuration) * 100;

    return Math.min(100, Math.max(0, progress));
  };

  const percentage = (item: VaultResponse) => {
    const fromRate = exchangeRates?.value.find((x) => x.currency === item.currency)?.rates;
    const toRate = exchangeRates?.value.find((x) => x.currency === activeWallet?.type)?.rates;

    const rate = fromRate?.buy === toRate?.buy ? 1 : Number(toRate?.buy) / Number(fromRate?.sell);

    const convertedAmount = item.amount * rate;

    return (convertedAmount / Number(totalVault)) * 100;
  };

  return (
    <div className="w-full max-w-[37.5rem]">
      <div className="flex justify-between items-center">
        <p className="font-medium text-base md:text-2xl">My Vaults</p>
      </div>

      <div className="w-full xl:w-[37.5rem] bg-side-bar-gradient border rounded-[10px] mt-5 md:mt-10">
        <div className="backdrop-blur-[30px] p-5">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-grey-text text-[0.625rem] md:text-sm">Total Portfolio</p>
              <div className="mt-3 text-xl md:text-3xl flex space-x-4 items-center">
                {visible && (
                  <p className="font-medium">
                    {activeWallet?.type.toUpperCase()} {formatAmount(String(totalVault))}
                  </p>
                )}
                {!visible && <p className="font-medium">************</p>}
                <button onClick={() => setVisible(!visible)}>
                  {visible && <Eye className="w-3 md:w-5" />}
                  {!visible && <EyeOff className="w-3 md:w-5" />}
                </button>
              </div>
            </div>

            <div>
              {wallets && (
                <button type="button" onClick={open} className="flex items-center space-x-2 py-5">
                  <Flag
                    code={activeWallet ? activeWallet.country.code : wallets.data[0].country.code}
                    className="w-6 h-6"
                  />
                  <p className="text-sm md:text-base">
                    {activeWallet
                      ? activeWallet.type.toUpperCase()
                      : wallets?.data[0].type.toUpperCase()}
                  </p>
                  <ChevronDown className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>

          <div className="mt-8 md:mt-10 flex justify-between items-center">
            <Button
              className="flex space-x-2.5 items-center md:h-auto"
              variant="purple"
              onClick={openCreateVaultModal}
            >
              <Plus className="w-2 md:w-5" />
              <span>Add Vault</span>
            </Button>
            <Link
              to={VAULT_PORTFOLIO_ROUTE}
              className="text-sm md:text-base font-medium text-platnova-purple"
            >
              View Portfolio
            </Link>
          </div>
        </div>
      </div>

      <Carousel className="md:w-full max-w-[80%] xl:max-w-full mx-auto mt-5 md:mt-10">
        <CarouselContent className="">
          {vaultResponse?.data.map((vault, idx) => (
            <CarouselItem
              key={idx}
              onClick={() => navigate(VAULT_DETAILS_ROUTE(vault.transaction.id))}
              className="cursor-pointer basis-auto"
            >
              <div className="bg-white rounded-[10px] p-3 md:p-4 shadow-sm w-full">
                <p className="text-[0.625rem] md:text-sm text-grey-text">
                  {vault.currency.toUpperCase()} Portfolio
                </p>
                <p className="mt-3 text-sm md:text-xl font-medium">
                  {vault.currency.toUpperCase()} {formatAmount(vault.amount.toString())}
                </p>

                <div className="mt-3 flex items-center">
                  <ProgressRing radius={20} stroke={5} progress={progress(vault)} />
                  {vault.status === 'active' && (
                    <p className="text-[0.625rem] md:text-xs text-grey-text">
                      {percentage(vault).toLocaleString(undefined, { maximumFractionDigits: 1 })}%
                      of total portfolio
                    </p>
                  )}
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>

      <div className="mt-5 md:mt-10">
        <div className="flex items-center justify-between">
          <p className="font-medium text-base md:text-xl mb-5">Recent Transactions</p>
          <button
            className="text-platnova-purple font-medium"
            onClick={() => navigate(TRANSACTIONS_ROUTE)}
          >
            See all
          </button>
        </div>
        <DashboardCardLayout>
          <div className="space-y-8">
            {vaultResponse?.data.slice(0, 3).map((transaction) => (
              <RecentActivityCard
                key={transaction.transaction.id}
                transaction={transaction.transaction}
                date="relative"
              />
            ))}
          </div>
        </DashboardCardLayout>
      </div>
      <SelectDepositWalletModal variant="currency" close={close} isOpen={isOpen} />
      <CreateVaultModal isOpen={createVaultModalIsOpen} close={closeCreateVaultModal} />
    </div>
  );
};

export default MyVaults;
