import Button from 'components/Buttons/Button';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Select from 'components/Inputs/select';
import Modal, { ModalProps } from 'components/modal';
import { useFormik } from 'formik';
import { X } from 'lucide-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCards, useUpdateCard } from 'services/cards';
import { useGetConfigurations } from 'services/configurations';
import { useAppDispatch } from 'store';
import { setRecipientCountry, setRecipientCurrency } from 'store/slices/wallet/walletSlice';
import { Configuration, RecipientCurrenciesValueType } from 'types/configuration';
import { removeNonDigit } from 'utils/numberFormat';
import RecipientInput from 'views/dashboard/accounts/send/component/recipientInput';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import { number, object, string } from 'yup';

const LimitModal = (props: ModalProps) => {
  const { data: getCardsResponse } = useGetCards();
  const dispatch = useAppDispatch();

  const { mutate: updateCard, isPending: isLoadingUpdateCard } = useUpdateCard();

  const { id } = useParams();

  const card = getCardsResponse?.data.find((card) => card.id === id);

  const formik = useFormik({
    initialValues: {
      amount: '',
      interval: 'Daily',
    },
    validationSchema: object().shape({
      amount: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .required(),
      interval: string().required(),
    }),
    onSubmit: (values) => {
      const newValues = {
        id: id as string,
        limits: [
          {
            amount: Number(removeNonDigit(values.amount)),
            interval: values.interval,
          },
        ] as [{ amount?: number; interval?: string }],
      };

      try {
        updateCard(newValues, {
          onSuccess: () => {
            formik.resetForm();
            props.close();
            notifySuccess('Limits set successfully');
          },
          onError: (err) => {
            handleErrors(err);
          },
        });
      } catch (err) {
        handleErrors(err);
      }
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
    };
  }

  function getSelectProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
    };
  }

  const intervalOptions = [
    {
      value: 'Daily',
      label: 'Daily',
    },
    {
      value: 'Weekly',
      label: 'Weekly',
    },
    {
      value: 'Monthly',
      label: 'Monthly',
    },
    {
      value: 'Yearly',
      label: 'Yearly',
    },
  ];

  const { data: configurations } = useGetConfigurations();

  const recipientCurrencies = configurations?.data.find(
    (x): x is Configuration<RecipientCurrenciesValueType> => x.name === 'recipient_currencies'
  )?.value;

  const activeCountry = recipientCurrencies?.find((x) => x.type === card?.currency);

  useEffect(() => {
    card &&
      activeCountry &&
      (dispatch(setRecipientCurrency(card.currency)),
      dispatch(setRecipientCountry(activeCountry.country.code)));
  }, [activeCountry, card, dispatch]);

  return (
    <Modal
      {...props}
      className="p-8 w-full max-w-xl md:max-w-2xl md:w-full scroll-component pb-24 xl:pb-8 space-y-6"
    >
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium capitalize">Set card limit</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>
      <p>
        The limit determines the amount that can be spent or withdrawn using this card within a
        period of time
      </p>
      <p>
        Eg. Daily interval and amount of NGN 20,000 means that only NGN 20,000 can be spent on the
        card within a day
      </p>
      <form className="space-y-6" onSubmit={formik.handleSubmit}>
        <DashboardCardLayout>
          <Select
            id="interval"
            label="Interval"
            options={intervalOptions}
            {...getSelectProps('interval')}
          />
          <RecipientInput
            variant="fixed"
            id="amount"
            label="Amount"
            {...getInputProps('amount')}
            value={formik.values['amount']}
          />
        </DashboardCardLayout>
        <Button type="submit" variant="purple" className="w-full" isLoading={isLoadingUpdateCard}>
          Set Limit
        </Button>
      </form>
    </Modal>
  );
};

export default LimitModal;
