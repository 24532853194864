import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useGetConfigurations } from 'services/configurations';
import { Configuration, ExchangeRate } from 'types/configuration';

import RecipientInput from './send/component/recipientInput';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const Rates = () => {
  const { data: configurationsResponse } = useGetConfigurations();

  const exchangeRates = configurationsResponse?.data.find(
    (x): x is Configuration<ExchangeRate> => x.name === 'exchange_rates'
  )?.value;

  const [currencyValues, setCurrencyValues] = useState<{ currency: string; value: number }[]>([]);

  useEffect(() => {
    if (exchangeRates && exchangeRates.length > 0) {
      setCurrencyValues(
        exchangeRates.map((currency) => ({
          currency: currency.currency,
          value: 0,
        }))
      );
    }
  }, [exchangeRates]);

  const handleInputChange = (changedCurrency: string, newValue: number) => {
    const targetCurrency = exchangeRates?.find((c) => c.currency === changedCurrency);
    if (!targetCurrency) return;

    const conversionRatio = newValue / targetCurrency.rates.buy;

    const updatedValues = exchangeRates?.map((currency) => ({
      currency: currency.currency,
      value: currency.rates.buy * conversionRatio,
    }));

    setCurrencyValues(updatedValues || []);
  };

  return (
    <SubRouteLayout header="Currency converter">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="mt-10 xl:mt-0">
            <p className="text-lg xl:text-2xl font-medium">Currency converter</p>
            <p className="mt-2 text-grey-text text-xs xl:text-base">
              Use the converter to compare exchange rates
            </p>

            <DashboardCardLayout className="mt-10 space-y-5">
              {currencyValues?.map(({ currency, value }) => (
                <div key={currency} style={{ marginBottom: '1rem' }}>
                  <RecipientInput
                    id={currency}
                    variant="fixed"
                    fixedCurrency={currency.toUpperCase()}
                    // type="number"
                    value={value}
                    onChange={(e) => handleInputChange(currency, parseFloat(e.target.value))}
                    style={{ marginLeft: '1rem', padding: '0.5rem', width: '200px' }}
                  />
                </div>
              ))}
            </DashboardCardLayout>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Rates;
