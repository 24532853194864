/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Flag from 'components/flag';
import Loader from 'components/Loader';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from 'lib/utils';
import { Search } from 'lucide-react';
import { useState } from 'react';
import { HiMiniEye, HiMiniEyeSlash } from 'react-icons/hi2';

import InputLabel from './inputLabel';
import { InputProps } from './types';

const Input = ({
  id,
  className,
  labelClassName,
  label,
  type = 'text',
  variant = 'primary',
  required,
  touched,
  error,
  containerClassName,
  value,
  helperText,
  eagerError,
  inputClassName,
  hiddenState = false,
  searchIcon = false,
  isLoading = false,
  countryCode,
  ...rest
}: InputProps) => {
  const [hidden, setHidden] = useState<boolean>(true);

  const toggleVisibility = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setHidden((prevState) => !prevState);
  };

  delete rest.initialError;
  delete rest.initialTouched;
  delete rest.initialValue;

  return (
    !hiddenState && (
      <div className="w-full">
        <div className="flex flex-col gap-2 mb-1">
          {((label && !!label.length) || (helperText && !!helperText.length)) && (
            <div className="flex flex-col items-start justify-center gap-1">
              {label && !!label.length && (
                <InputLabel
                  className={cn('text-xs md:text-sm font-normal', labelClassName)}
                  id={id}
                  label={label}
                />
              )}
              {helperText && !!helperText.length && (
                <label htmlFor={id} className="text-red-500 text-xs 2xl:text-sm font-medium">
                  {helperText}
                </label>
              )}
            </div>
          )}
          {type === 'password' && (
            <div
              className={cn(
                'relative flex w-full flex-row items-center overflow-hidden rounded-lg border border-transparent transition-all duration-300 ease-linear',
                [variant === 'primary' && 'bg-primary-grey'],
                [containerClassName && containerClassName]
              )}
            >
              <input
                type={hidden ? 'password' : 'text'}
                value={value}
                autoComplete="off"
                id={id}
                {...rest}
                className={cn(
                  'h-12 placeholder:text-grey-text text-primary-black w-full border border-transparent bg-transparent px-3 py-1 text-sm shadow-none placeholder:text-sm disabled:cursor-not-allowed rounded-lg',
                  'focus:border-2 focus:outline-none focus:border-platnova-purple',
                  [className && className],
                  [inputClassName && inputClassName],
                  [touched && error && 'border-error-primary']
                )}
                required={required}
              />

              <span className="absolute right-3 -translate-x-1/2">
                {!hidden ? (
                  <span
                    onClick={toggleVisibility}
                    className="text-grey-text select-none text-base font-medium w-6 h-4"
                  >
                    <HiMiniEye className="w-6 h-4" />
                  </span>
                ) : (
                  <span
                    onClick={toggleVisibility}
                    className="text-grey-text select-none text-base font-medium w-6 h-4"
                  >
                    <HiMiniEyeSlash className="w-6 h-4" />
                  </span>
                )}
              </span>
            </div>
          )}

          {(type === 'email' ||
            type === 'date' ||
            type === 'text' ||
            type === 'number' ||
            type === 'tel' ||
            type === 'time') && (
            <div
              className={cn(
                'flex w-full relative flex-row items-center overflow-hidden transition-all ease-linear rounded-lg',
                'focus-within:border focus-within:outline-none focus-within:border-platnova-purple',
                [variant === 'primary' && 'bg-primary-grey'],
                [variant === 'secondary' && 'bg-white border'],
                [containerClassName && containerClassName],
                [variant === 'flag' && 'bg-primary-grey']
              )}
            >
              {searchIcon && <Search className="absolute left-4 text-grey-text/40" size={16} />}
              {variant === 'flag' && (
                <div className="px-6">
                  <Flag code={countryCode || 'NG'} className="w-3 h-3 xl:w-6 xl:h-6" />
                </div>
              )}
              <input
                type={type}
                value={value}
                id={id}
                {...rest}
                className={cn(
                  'h-12 placeholder:text-grey-text text-primary-black w-full border border-transparent bg-transparent px-3 py-1 text-sm shadow-none placeholder:text-sm disabled:cursor-not-allowed rounded-lg',
                  'focus:border-none focus:outline-none',
                  searchIcon && 'pl-16',
                  [className && className],
                  [inputClassName && inputClassName],
                  [touched && error && variant !== 'flag' && 'border-error-primary']
                )}
                required={required}
              />
              {isLoading && <Loader title="" className="w-max" />}
            </div>
          )}
        </div>
        <AnimatePresence>
          <div className="h-5">
            {(eagerError || touched) && error && (
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ ease: 'easeOut', duration: 0.5 }}
                className="pl-1 text-xs md:text-sm font-semibold text-error-primary"
              >
                {error}
              </motion.div>
            )}
          </div>
        </AnimatePresence>
      </div>
    )
  );
};

export default Input;
