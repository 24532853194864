import { createElement } from 'react';
import { BILLS_ROUTE, GIFTCARDS_ROUTE, REFILL_ROUTE } from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import BillsPage from 'views/dashboard/refill/billsPage';
import Giftcards from 'views/dashboard/refill/giftcards';
import Refill from 'views/dashboard/refill/refill';

export const refillRoutes: AppRouteConfig[] = [
  {
    path: REFILL_ROUTE,
    element: createElement(Refill),
    restricted: true,
    showNav: true,
    showSideBar: true,
    isKycLimited: true,
  },
  {
    path: GIFTCARDS_ROUTE,
    element: createElement(Giftcards),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: BILLS_ROUTE(':country', ':id'),
    element: createElement(BillsPage),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
];
