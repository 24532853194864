import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import useDisclosure from 'hooks/useDisclosure';
import { Link } from 'react-router-dom';
import { BILLS_ROUTE } from 'routes/path';
import { useGetRefills } from 'services/refill';
import { Airtime } from 'services/refill/constants';
import { GiftCard } from 'services/refill/types';
import { useAppDispatch, useAppSelector } from 'store';
import { BILLS_COUNTRY_KEY, REFILL_SLICE_REDUCER_PATH } from 'store/slices/refill/constants';
import { setActiveRefill } from 'store/slices/refill/refillSlice';

import BillsPaymentModal from './components/billsPaymentModal';

const Bills = ({ type }: { type: 'airtime' | 'data' | 'cable' | 'electricity' | 'betting' }) => {
  const refillType = () => {
    switch (type) {
      case 'airtime':
        return 2;
      case 'data':
        return 3;
      case 'cable':
        return 4;
      case 'electricity':
        return 5;
      case 'betting':
        return 6;
    }
  };

  const { open, close, isOpen } = useDisclosure();
  const { [BILLS_COUNTRY_KEY]: country } = useAppSelector(
    (state) => state[REFILL_SLICE_REDUCER_PATH]
  );
  const dispatch = useAppDispatch();

  const customOpen = (active: GiftCard | Airtime) => {
    dispatch(setActiveRefill(active));
    open();
  };
  const { data: bettingResponse, isPending: isLoadingBettingResponse } = useGetRefills({
    country: country,
    page: 1,
    type: refillType(),
  });

  return (
    <div className="mt-6">
      <Carousel className="md:w-full max-w-[90%] mx-auto xl:max-w-[37.5rem] mt-3">
        <CarouselContent className="space-x-1 md:space-x-8">
          {bettingResponse?.data.items.map((item, idx) => (
            <CarouselItem
              key={idx}
              onClick={() => customOpen(item)}
              className="cursor-pointer w-20 md:w-24 basis-auto"
            >
              <img
                src={item.vas_meta.countries[0].logo}
                alt={item.name}
                className="rounded-lg object-cover h-16 w-full md:h-20 shrink-0"
              />
              <p className="mt-2 text-xs md:text-base text-center truncate">{item.name}</p>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div className="flex justify-end">
        <Link
          to={BILLS_ROUTE(country, refillType().toString())}
          className="mt-5 text-platnova-purple font-medium"
        >
          See all
        </Link>
      </div>
      {bettingResponse && !isLoadingBettingResponse && bettingResponse?.data.items.length < 1 && (
        <div>
          <p className="text-center">No product available</p>
        </div>
      )}
      <BillsPaymentModal isOpen={isOpen} close={close} />
    </div>
  );
};

export default Bills;
