import { createElement } from 'react';
import {
  ACCOUNTS_CONVERT_ROUTE,
  ACCOUNTS_DEPOSIT_FINALIZE_ROUTE,
  ACCOUNTS_DEPOSIT_ROUTE,
  ACCOUNTS_FINALIZE_SEND_ROUTE,
  ACCOUNTS_ROUTE,
  ACCOUNTS_SEND_ROUTE,
  ACCOUNTS_SETTINGS_ROUTE,
  DEPOSIT_BANK_ACCOUNT_ROUTE,
  DEPOSIT_REDIRECT_ROUTE,
  END_DEPOSIT_ROUTE,
  ISSUED_ACCOUNTS_ROUTE,
  MY_ACCOUNTS_ROUTE,
  RATES_ROUTE,
  SWAP_ROUTE,
  WALLET_ACCOUNT_DETAILS_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import Dashboard from 'views/dashboard/accounts';
import AccountDetails from 'views/dashboard/accounts/accountDetails';
import AccountSettings from 'views/dashboard/accounts/accountSettings';
import Convert from 'views/dashboard/accounts/convert/convert';
import BankAccount from 'views/dashboard/accounts/deposit/bankAccount';
import Deposit from 'views/dashboard/accounts/deposit/deposit';
import EndDeposit from 'views/dashboard/accounts/deposit/endDeposit';
import FinalizeDeposit from 'views/dashboard/accounts/deposit/finalizeDeposit';
import IframeDeposit from 'views/dashboard/accounts/deposit/iframeDeposit';
import IssuedAccount from 'views/dashboard/accounts/issuedAccount';
import MyAccounts from 'views/dashboard/accounts/myAccounts';
import Rates from 'views/dashboard/accounts/rates';
import FinalizeSend from 'views/dashboard/accounts/send/finalizeSend';
import SendLayout from 'views/dashboard/accounts/send/send.layout';
import Swap from 'views/dashboard/accounts/swap';

export const accountsRoutes: AppRouteConfig[] = [
  {
    path: ACCOUNTS_ROUTE,
    element: createElement(Dashboard),
    restricted: true,
    showNav: true,
    showSideBar: true,
    isKycLimited: true,
  },

  {
    path: MY_ACCOUNTS_ROUTE,
    element: createElement(MyAccounts),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ACCOUNTS_SETTINGS_ROUTE,
    element: createElement(AccountSettings),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ACCOUNTS_DEPOSIT_ROUTE,
    element: createElement(Deposit),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ISSUED_ACCOUNTS_ROUTE,
    element: createElement(IssuedAccount),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ACCOUNTS_DEPOSIT_FINALIZE_ROUTE,
    element: createElement(FinalizeDeposit),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ACCOUNTS_CONVERT_ROUTE,
    element: createElement(Convert),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: DEPOSIT_REDIRECT_ROUTE(':name'),
    element: createElement(IframeDeposit),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: DEPOSIT_BANK_ACCOUNT_ROUTE(':currency'),
    element: createElement(BankAccount),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: END_DEPOSIT_ROUTE,
    element: createElement(EndDeposit),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: WALLET_ACCOUNT_DETAILS_ROUTE(':type'),
    element: createElement(AccountDetails),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ACCOUNTS_SEND_ROUTE,
    element: createElement(SendLayout),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: ACCOUNTS_FINALIZE_SEND_ROUTE(':method'),
    element: createElement(FinalizeSend),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: RATES_ROUTE,
    element: createElement(Rates),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },

  {
    path: SWAP_ROUTE,
    element: createElement(Swap),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
];
