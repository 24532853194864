import Modal from 'components/modal';
import { X } from 'lucide-react';
import { Beneficiary } from 'services/beneficiary/constants';
import { useAppDispatch } from 'store';
import {
  setBeneficiary,
  setIsBeneficiary,
  setSendInputValues,
  setSendPaymentMethod,
  setSendPaymentStep,
} from 'store/slices/account/accountsSlice';
import { setRecipientCountry, setRecipientCurrency } from 'store/slices/wallet/walletSlice';

const BeneficiariesModal = ({
  isOpen,
  close,
  beneficiaries,
}: {
  isOpen: boolean;
  close: () => void;
  beneficiaries: Beneficiary[] | undefined;
}) => {
  const dispatch = useAppDispatch();
  const handleClickUser = (user: Beneficiary) => {
    const metaObj: { [key: string]: string } = {};
    user.meta.forEach((item) => {
      metaObj[item.id] = item.value;
    });
    dispatch(setSendInputValues(metaObj));
    dispatch(setSendPaymentMethod(user.payment_method));
    dispatch(setRecipientCurrency(user.currency));
    dispatch(setBeneficiary(user));
    dispatch(setSendPaymentStep(3));
    dispatch(setRecipientCountry(''));
    // dispatch(setFinalizeSendPage(1));
    dispatch(setIsBeneficiary(true));
    // navigate(ACCOUNTS_FINALIZE_SEND_ROUTE(sendPaymentMethod));
  };
  return (
    <Modal isOpen={isOpen} close={close} className="py-7 px-5 xl:w-full xl:max-w-xl">
      <div className="flex justify-between">
        <p className="text-lg xl:text-2xl font-medium">Beneficiaries</p>
        <X onClick={close} className="cursor-pointer" />
      </div>

      <div className="mt-6 xl:mt-12 space-y-2 xl:space-y-5">
        {beneficiaries?.map((beneficiary) => (
          <button
            onClick={() => handleClickUser(beneficiary)}
            key={beneficiary.id}
            className="bg-white w-full py-3 rounded-[10px] flex space-x-5"
          >
            <div className="bg-[#F5B12C] w-10 h-10 rounded-full flex justify-center items-center">
              <p className="text-base">
                {
                  beneficiary.meta.find(
                    (x) =>
                      x.id === 'account_name' ||
                      x.id === 'beneficiary_name' ||
                      x.id === 'revtag' ||
                      x.id === 'tag_id'
                  )?.value[0]
                }
              </p>
            </div>
            <div>
              <p className="text-xs xl:text-base text-start">
                {
                  beneficiary.meta.find(
                    (x) =>
                      x.id === 'account_name' || x.id === 'beneficiary_name' || x.id === 'revtag'
                  )?.value
                }
              </p>
              {beneficiary.payment_method === 'tag_transfer' && (
                <p className="text-xs xl:text-base text-start">
                  @{beneficiary.meta.find((x) => x.id === 'tag_id')?.value}
                </p>
              )}
              <p className="text-[0.625rem] xl:text-xs text-grey-text text-start mt-1">
                {beneficiary.payment_method !== 'bank_transfer' &&
                  beneficiary.payment_method !== 'otc_payout' &&
                  beneficiary.payment_method !== 'tag_transfer' &&
                  beneficiary.payment_method}
              </p>
              <p className="text-[0.625rem] xl:text-xs text-grey-text text-start mt-1">
                {beneficiary.meta.find((x) => x.id === 'bank_name')?.value}{' '}
                {beneficiary.meta.find((x) => x.id === 'account_number' || x.id === 'iban')?.value}
              </p>
            </div>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default BeneficiariesModal;
