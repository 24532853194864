export const VAULT_SLICE_REDUCER_PATH = 'vault_slice_reducer' as const;

const createVaultStage = ['type', 'input'] as const;

export interface VaultSliceType {
  stage: (typeof createVaultStage)[number];
  plan: { duration: number; rate: number; label: string; name: string } | null;
}

export const vaultSliceInitialState: VaultSliceType = {
  stage: 'type',
  plan: null,
};
