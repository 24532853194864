import Modal from 'components/modal';
import { X } from 'lucide-react';
import { DataCategory } from 'services/refill/constants';
import { useAppDispatch, useAppSelector } from 'store';
import { REFILL_SLICE_REDUCER_PATH } from 'store/slices/refill/constants';
import { setDataPlans } from 'store/slices/refill/refillSlice';
import { formatAmount } from 'utils/numberFormat';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

const PickDataPlanModal = ({
  isOpen,
  close,
  plans,
  image,
}: {
  isOpen: boolean;
  close: () => void;
  plans: DataCategory[];
  image: string;
}) => {
  const dispatch = useAppDispatch();
  const { activeRefill } = useAppSelector((state) => state[REFILL_SLICE_REDUCER_PATH]);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      className="p-8 w-full max-w-xl md:max-w-xl md:w-full scroll-component pb-24 xl:pb-8"
    >
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium capitalize">Select Data plan</p>
        <X size={25} className="cursor-pointer" onClick={close} />
      </div>

      {plans &&
        plans.map((plan) => (
          <button
            key={plan.name}
            className="w-full"
            onClick={() => {
              dispatch(setDataPlans(plan));
              close();
            }}
          >
            <DashboardCardLayout className="mt-3 flex space-x-6 items-center md:max-w-lg w-full">
              <img src={image} alt={plan.name} className="w-6 h-6 aspect-square object-contain" />
              <div className="text-start">
                <p className="text-sm md:text-lg font-medium">{plan.name}</p>
                <p className="text-xs md:text-sm text-grey-text">
                  {activeRefill.vas_meta?.countries[0].currency} {formatAmount(plan.amount)}
                </p>
              </div>
            </DashboardCardLayout>
          </button>
        ))}
    </Modal>
  );
};

export default PickDataPlanModal;
