import Button from 'components/Buttons/Button';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Flag from 'components/flag';
import Input from 'components/Inputs/input';
import InputLabel from 'components/Inputs/inputLabel';
import Modal, { ModalProps } from 'components/modal';
import { Checkbox } from 'components/ui/checkbox';
import VerifyPinForm from 'components/verifyPinForm';
import { useFormik } from 'formik';
import useDisclosure from 'hooks/useDisclosure';
import { cn, useGetExchangeValue } from 'lib/utils';
import { ChevronDown, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_PIN_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { useBuyGiftcard } from 'services/refill';
import { useAuthorizePin } from 'services/settings';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { REFILL_SLICE_REDUCER_PATH } from 'store/slices/refill/constants';
import { resetRefillState } from 'store/slices/refill/refillSlice';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import SelectDepositWalletModal from 'views/dashboard/accounts/components/selectDepositWalletModal';
import RecipientInput from 'views/dashboard/accounts/send/component/recipientInput';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import { number, object, string } from 'yup';

import SelectCountry from './selectCountry';

const PurchaseModal = ({ isOpen, close }: ModalProps) => {
  const [someoneElse, setSomeoneElse] = useState(false);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { activeRefill, giftcardCountry } = useAppSelector(
    (state) => state[REFILL_SLICE_REDUCER_PATH]
  );
  const dispatch = useAppDispatch();
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const [selectedValue, setSelectedValue] = useState({ base_amount: 0, unit_price: 0 });

  const { mutate: buyGiftcard, isPending: isLoadingBuyGiftcard } = useBuyGiftcard();

  const {
    mutate: authorizePin,
    isPending: isAuthorizePinPending,
    error: authorizePinError,
    isError: isAuthorizePinError,
    isSuccess,
  } = useAuthorizePin();

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const { data: account } = useGetAccount();
  const isPinValid = account?.data.additional_info.find((x) => x.id === 'is_pin_valid')?.value;

  const rate = useGetExchangeValue('usd', activeWallet?.type as string);

  const denominationKeys = Object.keys(giftcardCountry?.denomination || {})
    .map(Number)
    .sort((a, b) => a - b);
  const minPrice = denominationKeys[0];
  const maxPrice = denominationKeys[1];

  const formik = useFormik({
    initialValues: {
      debit_currency: activeWallet?.type || '',
      base_amount: selectedValue.base_amount,
      id: activeRefill.id,
      quantity: 1,
      sender_name: account?.data.first_name || '',
      recipient_email: '',
      unit_price: selectedValue.unit_price,
      country_code: giftcardCountry?.iso_code || '',
    },
    enableReinitialize: true,
    validationSchema: object().shape({
      unit_price:
        giftcardCountry?.denomination_type === 'RANGE'
          ? number()
              .transform((value, originalValue) => {
                return Number(removeNonDigit(String(originalValue)));
              })
              .required('kindly choose a price')
              .min(minPrice, `Enter a value no less than ${minPrice}`)
              .max(maxPrice, `Enter a value no more than ${maxPrice}`)
          : string().required('kindly choose a price'),
      debit_currency: string().required(),
      country_code: string().required(),
      recipient_email: someoneElse
        ? string().email('Enter a valid email').required('Email is required')
        : string(),
    }),
    onSubmit: () => {
      setPage(1);
    },
  });

  const paymentAmount = Number(formik.values.base_amount) * rate;

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
    };
  }
  const customClose = () => {
    formik.resetForm();
    dispatch(resetRefillState());
    setSomeoneElse(false);
    setSelectedValue({ base_amount: 0, unit_price: 0 });
    setPage(0);
    close();
  };

  const {
    isOpen: walletModalIsOpen,
    close: closeWalletModal,
    open: openWalletModal,
  } = useDisclosure();

  useEffect(() => {
    if (isSuccess) {
      const newValues = {
        ...formik.values,
        unit_price: Number(formik.values.unit_price),
        // base_amount: giftcardCountry?.denomination_type === 'RANGE' ?  ,
        recipient_email: someoneElse
          ? formik.values.recipient_email
          : (account?.data.email as string),
      };
      buyGiftcard(newValues, {
        onSuccess: () => {
          notifySuccess(`Giftcard purchased successfully`);
          formik.resetForm();
          customClose();
        },
        onError: (err) => {
          handleErrors(err);
        },
      });
    } else {
      null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      close={customClose}
      className="p-5 md:p-8 w-full max-w-xl md:max-w-2xl md:w-full scroll-component pb-24 xl:pb-8"
    >
      {page === 0 && activeRefill.type === 'giftcard' && (
        <div className="space-y-6">
          <div className="flex justify-between pb-3 border-b">
            <p className="text-xl md:text-2xl font-medium capitalize">
              Buy {activeRefill.name} {activeRefill.type}
            </p>
            <X size={25} className="cursor-pointer" onClick={customClose} />
          </div>

          <img
            src={activeRefill.giftcard_meta?.countries[0].logo}
            alt={activeRefill.name}
            className="rounded-[20px] object-cover w-1/2 mx-auto aspect-video shrink-0"
          />
          <div className="mt-10">
            <p>Issued Country</p>
            <SelectCountry />
          </div>

          {giftcardCountry && (
            <form className="" onSubmit={formik.handleSubmit}>
              {giftcardCountry.denomination_type === 'RANGE' && (
                <div>
                  <RecipientInput
                    variant="fixed"
                    fixedCurrency={giftcardCountry.currency}
                    id="unit_price"
                    label="Amount"
                    {...getInputProps('unit_price')}
                    value={formik.values['unit_price']}
                  />
                  <p className="text-grey-silent-text text-xs text-end">
                    Enter a value between{' '}
                    {Object.keys(giftcardCountry.denomination)
                      .sort((a, b) => Number(a) - Number(b))
                      .slice(0, 2)
                      .join(' and ')}
                  </p>
                </div>
              )}
              {giftcardCountry.denomination_type === 'FIXED' && (
                <div>
                  <InputLabel id="unit_price" label="Choose an amount below" />
                  <div className="grid grid-cols-4 gap-6 mt-3">
                    {Object.entries(giftcardCountry.denomination).map(([key, value]) => (
                      <button
                        onClick={() => {
                          setSelectedValue({ base_amount: Number(value), unit_price: Number(key) });
                        }}
                        type="button"
                        className={cn('p-2 w-full text-center bg-white shadow-sm rounded-md', [
                          selectedValue.unit_price.toString() === Number(key).toString() &&
                            'border-2 border-platnova-purple',
                        ])}
                        key={key}
                      >
                        {giftcardCountry.currency} {formatAmount(key)}
                      </button>
                    ))}
                  </div>
                  <p className="text-xs mt-2 font-medium text-red-500">
                    {formik.errors.unit_price}
                  </p>
                </div>
              )}
              <DashboardCardLayout className="flex space-x-6 items-center my-5">
                <Checkbox checked={someoneElse} onClick={() => setSomeoneElse(!someoneElse)} />
                <div>
                  <p className="text-sm md:text-base">Buy for someone else</p>
                  <p className="text-xs text-grey-text">
                    Send giftcard to your friends and loved ones
                  </p>
                </div>
              </DashboardCardLayout>
              {someoneElse && (
                <div>
                  <Input
                    className="placeholder:text-grey-silent-text"
                    id="recipient_email"
                    label="Recipient Email"
                    type="email"
                    placeholder="friend@mail.com"
                    {...getInputProps('recipient_email')}
                  />
                </div>
              )}

              <DashboardCardLayout className="space-y-2 w-full">
                <div className="flex justify-between items-center">
                  <p className="text-sm md:text-base text-black/70">Debit account</p>
                  {wallets && (
                    <button
                      type="button"
                      onClick={openWalletModal}
                      className="flex items-center space-x-2 py-5"
                    >
                      <Flag
                        code={
                          activeWallet ? activeWallet.country.code : wallets.data[0].country.code
                        }
                        className="w-6 h-6"
                      />
                      <p className="text-sm md:text-base">
                        {activeWallet
                          ? activeWallet.type.toUpperCase()
                          : wallets?.data[0].type.toUpperCase()}
                      </p>
                      <ChevronDown className="w-6 h-6" />
                    </button>
                  )}
                </div>
                <div className="space-y-7">
                  <div className="flex justify-between items-center">
                    <p className="text-sm md:text-base text-black/70">Gift Card Worth</p>
                    <p className="text-sm md:text-base font-medium">
                      {giftcardCountry.currency}{' '}
                      {formatAmount(String(formik.values.unit_price)) || '0.00'}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-sm md:text-base text-black/70">You will pay</p>
                    <p className="text-sm md:text-base font-medium">
                      {activeWallet?.type.toUpperCase()} {formatAmount(String(paymentAmount))}
                    </p>
                  </div>
                </div>
              </DashboardCardLayout>
              <Button className="w-full mt-6" variant="purple" type="submit">
                Buy
              </Button>
            </form>
          )}
        </div>
      )}

      {page === 1 && (
        <div className="space-y-6">
          <div className="flex justify-end pb-3 border-b">
            <X size={25} className="cursor-pointer" onClick={customClose} />
          </div>

          {isPinValid ? (
            <VerifyPinForm
              close={customClose}
              error={authorizePinError}
              isError={isAuthorizePinError}
              isPending={isAuthorizePinPending || isLoadingBuyGiftcard}
              mutate={authorizePin}
            />
          ) : (
            <div className="py-10 md:py-20 px-5 md:px-24 w-full max-w-xl flex flex-col items- space-y-5 text-center">
              <p className="text-lg md:text-2xl font-semibold">Create PIN</p>
              <p className="text-sm md:text-base">
                For further security, you are required to create a transaction pin to proceed with
                this transaction.
              </p>
              <Button
                variant="purple"
                onClick={() => {
                  customClose();
                  navigate(SETTINGS_PIN_ROUTE);
                }}
              >
                Create Transaction Pin
              </Button>
            </div>
          )}
        </div>
      )}

      <SelectDepositWalletModal
        variant="debit"
        close={closeWalletModal}
        isOpen={walletModalIsOpen}
      />
    </Modal>
  );
};

export default PurchaseModal;
