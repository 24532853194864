import { Beneficiary } from 'services/beneficiary/constants';

export const searchBeneficiaries = (data: Beneficiary[] | undefined, searchTerm: string) => {
  const lowercasedTerm = searchTerm.toLowerCase();

  if (data && searchTerm.length > 0) {
    return data.filter((item) =>
      item.meta.some(
        (metaItem) =>
          ['account_name', 'bank_name', 'account_number'].includes(metaItem.id) &&
          metaItem.value.toLowerCase().includes(lowercasedTerm)
      )
    );
  } else {
    return data;
  }
};
