import Button from 'components/Buttons/Button';
import MoneyInput from 'components/Inputs/moneyInput';
import { useGetExchangeRate, useGetFormattedExchangeRate, useGetWithdrawCardFees } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { CARDS_ROUTE } from 'routes/path';
import { useGetCards } from 'services/cards';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { CARD_INDEX, CARD_SLICE_REDUCER_PATH } from 'store/slices/cards/constants';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';

import { useWithdrawCardForm } from './hooks/useWithdrawCardForm';
import PaymentMethod from '../accounts/components/paymentMethod';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const DepositCard = () => {
  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();

  const {
    values,
    getInputProps,
    onSubmit,
    isPending: isLoadingWithdrawCard,
    setValue,
  } = useWithdrawCardForm();

  const { [CARD_INDEX]: cardIndex } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const { data: getCardsResponse } = useGetCards();

  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const exchange = useGetFormattedExchangeRate(
    activeWallet?.type as string,
    getCardsResponse?.data[cardIndex].currency as string
  );

  const withdrawFees = useGetWithdrawCardFees();

  const percentage = withdrawFees?.fee.percentage;
  const flat = withdrawFees && withdrawFees.fee.flat;

  const fee =
    (Number(removeNonDigit(String(values.amount))) * Number(percentage)) / 100 + Number(flat);

  const handleShowFee = (fee: string | number, currency?: string) => {
    if (fee === 0 || fee === '0' || fee === '0.00') {
      return 'Free';
    } else {
      return currency + ' ' + formatAmount(String(fee));
    }
  };

  useEffect(() => {
    setValue('currency', activeWallet?.type);
  }, [activeWallet, setValue]);

  const exchangeRate = useGetExchangeRate(activeWallet?.type as string);

  const amountInCardCurrency =
    activeWallet?.type !== getCardsResponse?.data[cardIndex].currency &&
    exchangeRate &&
    exchangeRate.rates
      ? Number(removeNonDigit(values.amount.toString())) / exchangeRate.rates.sell
      : Number(removeNonDigit(values.amount.toString()));

  // console.log(withdrawFees)
  return (
    <SubRouteLayout header="Withdraw from card">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={CARDS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <form className="space-y-8 mt-5 xl:mt-0 xl:space-y-10" onSubmit={onSubmit}>
            <div>
              <p className="text-lg xl:text-2xl font-medium">Withdraw from card</p>
              <p className="mt-2 text-grey-text text-xs xl:text-base">
                Enter amount to withdraw from the card
              </p>
            </div>

            <DashboardCardLayout className="xl:w-[37.5rem]">
              {!isLoadingWallets && wallets && wallets.data.length ? (
                <MoneyInput
                  label="Amount to withdraw"
                  id="amount"
                  {...getInputProps('amount')}
                  value={values['amount']}
                />
              ) : (
                <Skeleton className="h-[8.2rem]" />
              )}
            </DashboardCardLayout>

            <DashboardCardLayout className="xl:w-[37.5rem]">
              <PaymentMethod type="deposit-card" />
            </DashboardCardLayout>

            <DashboardCardLayout className="xl:w-[37.5rem] space-y-3 xl:space-y-6">
              <div className="flex justify-between items-center">
                <p className="text-xs xl:text-base">Rate</p>
                <p className="text-xs xl:text-base font-medium">{exchange}</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs xl:text-base">
                  Fee ({percentage ? percentage : 0}%
                  {(flat as number) > 0 && ` + ${activeWallet?.type.toUpperCase()} ${flat}`})
                </p>
                <p className="text-xs xl:text-base font-medium">
                  {isNaN(fee)
                    ? handleShowFee(0)
                    : handleShowFee(fee, activeWallet?.type.toUpperCase())}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs xl:text-base">Amount deducted from card</p>
                <p className="text-xs xl:text-base font-medium">
                  {getCardsResponse?.data[cardIndex].currency.toUpperCase()}{' '}
                  {isNaN(Number(removeNonDigit(String(amountInCardCurrency))) + fee)
                    ? 0
                    : formatAmount(
                        String(Number(removeNonDigit(String(amountInCardCurrency))) + fee)
                      )}
                </p>
              </div>
            </DashboardCardLayout>

            <Button
              variant="purple"
              className="w-full"
              type="submit"
              isLoading={isLoadingWithdrawCard}
            >
              Withdraw
            </Button>
          </form>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default DepositCard;
