import Button from 'components/Buttons/Button';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Flag from 'components/flag';
import VerifyPinForm from 'components/verifyPinForm';
import { useFormik } from 'formik';
import useDisclosure from 'hooks/useDisclosure';
import {
  useGetExchangeValue,
  useGetFormattedExchangeRate,
  useGetVaultDepositLimit,
} from 'lib/utils';
import { ChevronDown, ChevronLeft } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SETTINGS_PIN_ROUTE, VAULT_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { useGetConfigurations } from 'services/configurations';
import { useAuthorizePin } from 'services/settings';
import { useCreateVault } from 'services/vault';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { VAULT_SLICE_REDUCER_PATH } from 'store/slices/vault/constants';
import { resetVaultState, setCreateVaultStage } from 'store/slices/vault/vaultSlice';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { Configuration, VaultPlansValue } from 'types/configuration';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import { number, object, string } from 'yup';

import VaultPlansModal from './vaultPlansModal';
import SelectDepositWalletModal from '../accounts/components/selectDepositWalletModal';
import RecipientInput from '../accounts/send/component/recipientInput';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const CreateVaultPlan = () => {
  const params = useParams();
  const { data: configurations } = useGetConfigurations();
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const { mutate: createVault } = useCreateVault();
  const navigate = useNavigate();

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const vaultPlans = configurations?.data.find(
    (item): item is Configuration<VaultPlansValue> => item.name === 'vault_plans'
  )?.value;

  const {
    isOpen: walletModalIsOpen,
    close: closeWalletModal,
    open: openWalletModal,
  } = useDisclosure();

  const {
    isOpen: vaultPlansModalIsOpen,
    close: closeVaultPlansModal,
    open: openVaultPlansModal,
  } = useDisclosure();

  const plan = vaultPlans?.find((item) => item.code === params.id);

  const { plan: cachedPlan } = useAppSelector((state) => state[VAULT_SLICE_REDUCER_PATH]);

  const limits = useGetVaultDepositLimit(plan?.name || '');

  const formik = useFormik({
    initialValues: {
      plan_name: cachedPlan?.name || '',
      plan_currency: plan?.name || '',
      plan_amount: '',
      currency: activeWallet?.type || '',
    },
    enableReinitialize: true,
    validationSchema: object().shape({
      plan_name: string().required(),
      plan_currency: string().required(),
      plan_amount: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .required('Enter a valid amount')
        .min(
          limits?.min ?? 0,
          `Minimum ${formatAmount(String(limits?.min))} ${plan?.name.toUpperCase()}`
        )
        .max(
          limits?.max ?? 0,
          `Maximum ${formatAmount(String(limits?.max))} ${plan?.name.toUpperCase()}`
        ),
      currency: string().required(),
    }),
    onSubmit: async () => {
      // const newValues = { ...data, plan_amount: Number(removeNonDigit(data.plan_amount)) }
      // try {
      //   createVault(newValues, {
      //     onSuccess: () => {
      //       formik.resetForm()
      //       dispatch(resetVaultState())
      //       navigate(VAULT_ROUTE)
      //       notifySuccess('Vault created successfully')
      //     }
      //   })
      // } catch (err) {
      //   handleErrors(err)
      // }
      setPage(1);
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
    };
  }

  const exchange = useGetFormattedExchangeRate(activeWallet?.type as string, plan?.name as string);
  const exchangeValue = useGetExchangeValue(plan?.name ?? '', activeWallet?.type ?? '');

  const total = cachedPlan
    ? Number(formik.values.plan_amount) +
      Number(formik.values.plan_amount) * (cachedPlan?.rate / 100)
    : 0;

  const { data: account } = useGetAccount();
  const isPinValid = account?.data.additional_info.find((x) => x.id === 'is_pin_valid')?.value;

  // const customClose = () => {
  //   setPage(0)
  // }

  const {
    mutate: authorizePin,
    isPending: isAuthorizePinPending,
    error: authorizePinError,
    isError: isAuthorizePinError,
    isSuccess,
  } = useAuthorizePin();

  useEffect(() => {
    if (isSuccess) {
      const newValues = {
        ...formik.values,
        plan_amount: Number(formik.values.plan_amount),
      };
      try {
        createVault(newValues, {
          onSuccess: () => {
            formik.resetForm();
            dispatch(resetVaultState());
            navigate(VAULT_ROUTE);
            notifySuccess('Vault created successfully');
          },
        });
      } catch (err) {
        handleErrors(err);
      }
    } else {
      null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <SubRouteLayout header="Vault">
      {page === 0 && (
        <div className="pb-20 xl:-ml-[4.688rem]">
          <div className="xl:flex items-start xl:space-x-11">
            <button
              onClick={() => dispatch(setCreateVaultStage({ stage: 'type' }))}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="space-y-10 xl:w-[37.5rem]">
              <div className="space-y-3">
                <p className="text-lg md:text-2xl font-medium capitalize mt-10 md:mt-0">
                  How much do you want to save?
                </p>
                <p className="text-xs md:text-base text-black/70">
                  Enter the amount you want to save and select your desired source of funds
                </p>

                <form onSubmit={formik.handleSubmit} className="space-y-3 md:space-y-6">
                  <DashboardCardLayout>
                    <RecipientInput
                      variant="fixed"
                      fixedCurrency={plan?.name.toUpperCase()}
                      label="Amount"
                      id="plan_amount"
                      {...getInputProps('plan_amount')}
                      value={formik.values['plan_amount']}
                    />
                    <p className="text-end text-grey-text text-[0.625rem] md:text-base mt-2">
                      You will earn{' '}
                      <span className="font-medium text-platnova-purple">
                        {plan?.name.toUpperCase()} {formatAmount(total.toString())}
                      </span>
                    </p>
                  </DashboardCardLayout>

                  <div>
                    <p className="font-medium text-sm md:text-xl">Choose Source of Funds</p>
                    <DashboardCardLayout>
                      <button
                        type="button"
                        onClick={openWalletModal}
                        className="flex items-center justify-between py-2 md:py-4 bg-primary-grey w-full px-4 md:px-6"
                      >
                        <div className="flex items-center space-x-6">
                          {wallets && (
                            <Flag
                              code={
                                activeWallet
                                  ? activeWallet.country.code
                                  : wallets?.data[0].country.code
                              }
                              className="w-6 h-6"
                            />
                          )}
                          <p className="text-sm md:text-base">
                            {activeWallet
                              ? activeWallet.type.toUpperCase() +
                                ' ' +
                                formatAmount(activeWallet.amount)
                              : wallets?.data[0].type.toUpperCase() +
                                ' ' +
                                formatAmount(wallets?.data[0].amount || '')}
                          </p>
                        </div>
                        <ChevronDown className="w-6 h-6" />
                      </button>
                    </DashboardCardLayout>
                  </div>

                  <DashboardCardLayout className="space-y-5">
                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">Plan</p>
                      <button
                        type="button"
                        onClick={openVaultPlansModal}
                        className="flex items-center space-x-2"
                      >
                        <p className="text-sm md:text-base capitalize">{cachedPlan?.name}</p>
                        <ChevronDown className="w-6 h-6" />
                      </button>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">Interest rate</p>
                      <p className="text-sm md:text-base">{cachedPlan?.rate}%</p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">Maturity date</p>
                      <p className="text-sm md:text-base">
                        {moment().add(cachedPlan?.duration, 'days').format('MMM DD, YYYY')}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">Total to earn</p>
                      <p className="text-sm md:text-base">
                        {plan?.name.toUpperCase()} {formatAmount(total.toString())}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">Rate</p>
                      <p className="text-sm md:text-base">{exchange}</p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">Fee (0%)</p>
                      <p className="text-sm md:text-base">Free</p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-grey-text text-xs md:text-base">You will pay</p>
                      <p className="text-sm md:text-base">
                        {activeWallet?.type.toUpperCase()}{' '}
                        {formatAmount(
                          (Number(formik.values.plan_amount) * exchangeValue).toString()
                        )}
                      </p>
                    </div>
                  </DashboardCardLayout>

                  <p className="text-center text-xs md:text-base text-grey-text">
                    Your interest of <span className="font-semibold">{cachedPlan?.rate}%</span> and
                    your locked funds will be
                    <br /> released on{' '}
                    {moment().add(cachedPlan?.duration, 'days').format('MMM DD, YYYY')}
                  </p>
                  <Button
                    variant="purple"
                    type="submit"
                    isLoading={formik.isSubmitting}
                    className="w-full"
                  >
                    Lock Money
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {page === 1 && (
        <div className="space-y-6">
          {isPinValid ? (
            <VerifyPinForm
              close={() => setPage(0)}
              error={authorizePinError}
              isError={isAuthorizePinError}
              isPending={isAuthorizePinPending || formik.isSubmitting}
              mutate={authorizePin}
            />
          ) : (
            <div className="py-10 md:py-20 px-5 md:px-24 w-full max-w-xl flex flex-col items- space-y-5 text-center">
              <p className="text-lg md:text-2xl font-semibold">Create PIN</p>
              <p className="text-sm md:text-base">
                For further security, you are required to create a transaction pin to proceed with
                this transaction.
              </p>
              <Button
                variant="purple"
                onClick={() => {
                  navigate(SETTINGS_PIN_ROUTE);
                }}
              >
                Create Transaction Pin
              </Button>
            </div>
          )}
        </div>
      )}
      <SelectDepositWalletModal
        variant="debit"
        close={closeWalletModal}
        isOpen={walletModalIsOpen}
      />
      <VaultPlansModal close={closeVaultPlansModal} isOpen={vaultPlansModalIsOpen} />
    </SubRouteLayout>
  );
};

export default CreateVaultPlan;
