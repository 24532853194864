import { DEPOSIT_REDIRECT_URL } from 'lib/constants/accounts/deposit';
import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ACCOUNTS_DEPOSIT_ROUTE } from 'routes/path';
import { useAppSelector } from 'store';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import PaymentIframe from './components/paymentIframe';

const IframeDeposit = () => {
  const { [DEPOSIT_REDIRECT_URL]: url } = useAppSelector((state) => state.account);
  // const selectedScript = getNowPaymentScript();

  const navigate = useNavigate();
  // console.log(url)
  useEffect(() => {
    !url && navigate(ACCOUNTS_DEPOSIT_ROUTE);
  });

  return (
    url && (
      <SubRouteLayout header="Account Details">
        <div className="pb-20 xl:-ml-[4.688rem] xl:mx-0">
          <div className="xl:flex items-start xl:space-x-11 max-w-[43.75rem]">
            <Link
              to={ACCOUNTS_DEPOSIT_ROUTE}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </Link>

            <div className="mt-5 xl:mt-0 w-full md:w-[52rem] h-screen">
              <div style={{ width: '100%', height: '100%' }}>
                <PaymentIframe url={url} />
              </div>
            </div>
          </div>
        </div>
      </SubRouteLayout>
    )
  );
};

export default IframeDeposit;
