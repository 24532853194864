import Layout from 'components/layout';
import Initialization from 'middlewares/initialize';
import { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import routes from 'routes';

function App() {
  const views = useRoutes(routes);

  return (
    <Fragment>
      <Layout>
        {Initialization({
          children: <div>{views}</div>,
        })}
        <Toaster position="top-right" />
      </Layout>
    </Fragment>
  );
}

export default App;
