import Button from 'components/Buttons/Button';
import MoneyInput from 'components/Inputs/moneyInput';
import Loader from 'components/Loader';
import useDisclosure from 'hooks/useDisclosure';
import { allCountries } from 'lib/countries/countries';
import {
  useGetCreateVirtualCardFees,
  useGetExchangeRate,
  useGetFormattedExchangeRate,
} from 'lib/utils';
import { AiFillInfoCircle } from 'react-icons/ai';
import {
  CREATE_VIRTUAL_CARD_AMOUNT_KEY,
  CREATE_VIRTUAL_CARD_BRAND_KEY,
} from 'services/cards/constants';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { CARD_SLICE_REDUCER_PATH, VIRTUAL_CARD_COLOR_KEY } from 'store/slices/cards/constants';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

import { useCreateVirtualCardForm } from '../hooks/useCreateVirtualCardForm';

const CreateVirtualCardForm = () => {
  const { getInputProps, submit, values, errors, isLoadingCreateVirtualCard } =
    useCreateVirtualCardForm();
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const exchange = useGetFormattedExchangeRate(activeWallet?.type as string, 'usd');

  const { [VIRTUAL_CARD_COLOR_KEY]: virtualCardColor } = useAppSelector(
    (state) => state[CARD_SLICE_REDUCER_PATH]
  );

  const us = allCountries.find((x) => x.cca2 === 'US');

  const { open: openCardCurrencyModal } = useDisclosure();

  const exchangeRate = useGetExchangeRate(activeWallet?.type as string);

  const amountInUsd =
    activeWallet?.type !== 'usd' && exchangeRate && exchangeRate.rates
      ? Number(removeNonDigit(values[CREATE_VIRTUAL_CARD_AMOUNT_KEY].toString())) /
        exchangeRate.rates.sell
      : Number(removeNonDigit(values[CREATE_VIRTUAL_CARD_AMOUNT_KEY].toString()));

  const createCardFees = useGetCreateVirtualCardFees();

  const percentage = createCardFees?.fee.percentage;
  const flat = createCardFees?.fee.flat;

  const fee =
    (Number(removeNonDigit(String(values[CREATE_VIRTUAL_CARD_AMOUNT_KEY]))) * Number(percentage)) /
      100 +
    Number(flat);

  const handleShowFee = (fee: string | number, currency?: string) => {
    if (fee === 0 || fee === '0' || fee === '0.00') {
      return 'Free';
    } else {
      return formatAmount(String(fee)) + ' ' + currency;
    }
  };

  const initialAmount = fee ? amountInUsd - fee : 0;

  return (
    <div>
      {wallets && activeWallet ? (
        <form className="w-full mt-10 space-y-10" onSubmit={submit}>
          <div className="bg-[#8BB2FF4D] rounded-[10px] py-4 xl:py-8 px-3 xl:px-6 flex space-x-5 items-center w-full xl:w-[37.5rem]">
            <AiFillInfoCircle className="stroke-[#8BB2FF4D] fill-[#021E65] w-9 h-9" />
            <p className="font-light text-[#021E65] text-xs xl:text-base">
              To avoid delays, please ensure that the cardholder name matches the name on your
              account.
            </p>
          </div>
          <DashboardCardLayout className=" space-y-2">
            <MoneyInput
              id={CREATE_VIRTUAL_CARD_AMOUNT_KEY}
              variant="send"
              {...getInputProps(CREATE_VIRTUAL_CARD_AMOUNT_KEY)}
              error={errors[CREATE_VIRTUAL_CARD_AMOUNT_KEY]}
              value={values[CREATE_VIRTUAL_CARD_AMOUNT_KEY]}
            />
          </DashboardCardLayout>

          <DashboardCardLayout>
            <div className="bg-primary-grey p-2 xl:p-5 flex justify-between">
              <div>
                <div className="flex justify-between">
                  <div className="flex items-center space-x-3 xl:space-x-6">
                    <img src={us?.flags.svg} alt="gg" className="w-6 h-6 xl:w-10 xl:h-10" />
                    <div className="flex flex-col items-start text-start">
                      <p className="text-sm xl:text-base">USD</p>
                      <p className="text-[0.625rem]] xl:text-xs text-grey-text">
                        Virtual dollar card
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                variant="secondary"
                type="button"
                onClick={openCardCurrencyModal}
                className="py-1.5 px-2 xl:py-3 xl:px-5 h-max xl:ml-5 shadow-none my-auto"
              >
                Change
              </Button>
            </div>
          </DashboardCardLayout>

          <DashboardCardLayout className="space-y-2">
            <div className="space-y-7">
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Rate</p>
                <p className="text-sm md:text-base font-medium">{exchange}</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Amount in USD</p>
                <p className="text-sm md:text-base font-medium">
                  {formatAmount(amountInUsd.toString())} USD
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">
                  Issuance Fee ({percentage ? percentage : 0}%
                  {(flat as number) > 0 && ` + ${flat} USD`})
                </p>
                <p className="text-sm md:text-base font-medium">
                  {isNaN(fee) ? handleShowFee(0) : handleShowFee(fee, 'USD')}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Inital amount in card</p>
                <p className="text-sm md:text-base font-medium">
                  {initialAmount < 1 ? '0.00' : formatAmount(initialAmount.toString())} USD
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Card color</p>
                <div
                  className="p-3 rounded-full"
                  style={{ background: virtualCardColor.class }}
                ></div>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Type</p>
                <p className="text-sm md:text-base font-medium capitalize">
                  {values[CREATE_VIRTUAL_CARD_BRAND_KEY]}
                </p>
              </div>
            </div>
          </DashboardCardLayout>

          <Button
            type="submit"
            variant="purple"
            isLoading={isLoadingCreateVirtualCard}
            className="w-full"
          >
            Create
          </Button>
        </form>
      ) : (
        <Loader />
      )}
      {/* <SelectDepositWalletModal variant="debit" close={close} isOpen={isOpen} /> */}
    </div>
  );
};

export default CreateVirtualCardForm;
