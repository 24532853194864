import { createElement } from 'react';
import {
  VAULT_DETAILS_ROUTE,
  VAULT_PLANS_ROUTE,
  VAULT_PORTFOLIO_ROUTE,
  VAULT_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import Vault from 'views/dashboard/vault/vault';
import VaultDetails from 'views/dashboard/vault/vault-details';
import VaultPortfolio from 'views/dashboard/vault/vault-portfolio';
import VaultPlans from 'views/dashboard/vault/vaultPlans';

export const vaultRoutes: AppRouteConfig[] = [
  {
    path: VAULT_ROUTE,
    element: createElement(Vault),
    restricted: true,
    showNav: true,
    showSideBar: true,
    isKycLimited: true,
  },
  {
    path: VAULT_PLANS_ROUTE(':id'),
    element: createElement(VaultPlans),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: VAULT_DETAILS_ROUTE(':id'),
    element: createElement(VaultDetails),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: VAULT_PORTFOLIO_ROUTE,
    element: createElement(VaultPortfolio),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
];
